type Stage = 'program1' | 'program2' | 'program3';

const program1KeyList = (trainingId: string) => [
  `@useStatePerist:program1-${trainingId}-text3`,
  `@useStatePerist:program1-${trainingId}-text4`,
  `@useStatePerist:program1-${trainingId}-text5`,
  `@useStatePerist:program1-${trainingId}-text6`,
  `@useStatePerist:program1-${trainingId}-text7`,
  `@useStatePerist:program1-${trainingId}-konro`,
  `@useStatePerist:program1-${trainingId}-stove`,
  `@useStatePerist:program1-${trainingId}-tobacco`,
  `timer:${trainingId}-program1answer`,
];
const program2KeyList = (trainingId: string) => [
  `@useStatePerist:program2-${trainingId}-currentQuestion`,
  `@useStatePerist:program2-${trainingId}-selectedCard`,
  `@useStatePerist:program2-${trainingId}-effectedCard`,
  `@useStatePerist:program2-${trainingId}-resultCount`,
  `@useStatePerist:program2-${trainingId}-result`,
  `@useStatePerist:program2-${trainingId}-data`,
  `@useStatePerist:program2-${trainingId}-total`,
  `@useStatePerist:program2-${trainingId}-resultSet`,
  `@useStatePerist:program2-${trainingId}-finish`,
  `timer:${trainingId}-program2-general`,
  `timer:${trainingId}-program2-0`,
  `timer:${trainingId}-program2-1`,
  `timer:${trainingId}-program2-2`,
];
const program3KeyList = (trainingId: string) => [
  `@useStatePerist:program3-${trainingId}-resultSet`,
  `@useStatePerist:program3-${trainingId}-stage1-finished`,
  `@useStatePerist:program3-${trainingId}-stage1-priorityBorder`,
  `@useStatePerist:program3-${trainingId}-stage1-step1Answers`,
  `@useStatePerist:program3-${trainingId}-stage1-step3Answers`,
  `@useStatePerist:program3-${trainingId}-stage2-finished`,
  `@useStatePerist:program3-${trainingId}-stage2-priorityBorder`,
  `@useStatePerist:program3-${trainingId}-stage2-step1Answers`,
  `@useStatePerist:program3-${trainingId}-stage2-step3Answers`,
  `@useStatePerist:program3-${trainingId}-resultSet`,
  `@useStatePerist:program3-${trainingId}-currentFocus`,
  `${trainingId}-program3-stage1`,
  `${trainingId}-program3-stage2`,
];

const keyLists = {
  program1: program1KeyList,
  program2: program2KeyList,
  program3: program3KeyList,
};

export const hasData = (stage: Stage, trainingId: string) => {
  const list = keyLists[stage](trainingId);
  console.log(list);
  return list.some(key => localStorage.getItem(key));
};
export const deleteData = (stage: Stage, trainingId: string) => {
  const list = keyLists[stage](trainingId);
  list.forEach(key => localStorage.removeItem(key));
};
