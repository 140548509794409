import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Input,
  MainButton,
  Textarea,
} from '@riddler-co-jp/specc-ui-components';
import styled from 'styled-components';

import IconStep1 from '../../../../static/png/step1.png';
import { tuple } from './Types';

interface Step1Props {
  onAdd: () => void;
  onSetStep1Answers: (s: tuple[]) => void;
  onDeleteStep1Answer: (id: number) => void;
  answers: tuple[];
  title: string;
}

const Program3Step1: React.FC<Step1Props> = props => {
  return (
    <Wrapper>
      <Step src={IconStep1} />
      <h2>{props.title}</h2>
      <MyWrapper>
        <ul>
          <li>班分けの条件</li>
          <li>根拠・補足</li>
          <li></li>
        </ul>
        <ul>
          {props.answers.map((single, i) => {
            return (
              <AnswerLi key={i}>
                <Input
                  fullWidth
                  maxLength={30}
                  placeholder='班分けの条件を入力してください'
                  value={props.answers[single.id].condition}
                  onChange={(val: string) => {
                    const tmp = props.answers;
                    tmp[single.id].condition = val;
                    props.onSetStep1Answers(tmp);
                  }}
                />
                <Textarea
                  maxLength={50}
                  placeholder='条件の根拠や理由を入力してください'
                  value={props.answers[single.id].reason}
                  onChange={(val: string) => {
                    const tmp = props.answers;
                    tmp[single.id].reason = val;
                    props.onSetStep1Answers(tmp);
                  }}
                />
                <div
                  style={
                    props.answers.length <= 1
                      ? { opacity: 0.4, pointerEvents: 'none' }
                      : {}
                  }
                  onClick={() => {
                    if (props.answers.length > 1)
                      props.onDeleteStep1Answer(single.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </AnswerLi>
            );
          })}
        </ul>
        <ButtonWrapper>
          <Button
            color='positive'
            size='medium'
            variant='sub'
            onMouseDown={props.onAdd}
          >
            記入欄を追加
          </Button>
        </ButtonWrapper>
      </MyWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  padding: 10px 0;
`;

const MyWrapper = styled.div`
  width: 100%;
  background: #f3f4f6;
  border: 1px solid #ced4da;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 20px;

  ul:first-child {
    padding: 10px 12px;
    display: flex;

    li:nth-child(1) {
      width: 340px;
      margin-right: 10px;
    }
    li:nth-child(2) {
      flex: 1;
      margin-right: 10px;
    }
  }
`;

const AnswerLi = styled.li`
  display: flex;
  width: calc(100% - 24px);
  padding: 10px 12px;

  > div:nth-child(1) {
    width: 340px;
    margin-bottom: 0;
    margin-right: 10px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-bottom: 0;
    margin-right: 10px;
  }
  > div:last-child {
    color: #868e96;
    font-size: 2rem;
    padding: 5px 8px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      color: #ea0e49;
    }
  }

  &:nth-child(2n-1) {
    background: white;
  }
`;

const Step = styled.img`
  display: block;
  margin-bottom: 20px;
  margin-left: -40px;
  height: 42px;
  width: auto;
`;
export default Program3Step1;
