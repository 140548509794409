import { FinishWindow, MainButton } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../../lib/instructionSettings';
import TimeGauge from '../../../../uiElements/timeGauge/TimeGauge';
import Step1 from '../Program3Step1';
import Step2 from '../Program3Step2';
import { Item, Program3State, tuple } from '../Types';

interface Program3AnswerProps {
  stage1Data: Item | null;
  priorityBorder: number;
  step1Answers: tuple[];
  step3Answers: number[][];
}

const Program3Answer: React.FC<Program3AnswerProps> = props => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();

  const [finished, setFinished] = useState<boolean>(
    `program3-${trainingId}-stage2-finished`,
    false
  );
  const [priorityBorder, setPriorityBorder] = useState<number>(
    `program3-${trainingId}-stage2-priorityBorder`,
    props.priorityBorder
  );
  const [step1Answers, setStep1Answers] = useState<tuple[]>(
    `program3-${trainingId}-stage2-step1Answers`,
    props.step1Answers
  );
  const [step3Answers, setStep3Answers] = useState<number[][]>(
    `program3-${trainingId}-stage2-step3Answers`,
    props.step3Answers
  );
  const [resultSet, setResultSet] = useState<any>(
    `program3-${trainingId}-stage2-resultSet`
  );

  const goTo = (url: string) => {
    const data: Item = {
      step1: step1Answers,
      priorityBorder: priorityBorder,
      step3: step3Answers,
    };

    const s: Program3State = {
      stage1: props.stage1Data ?? null,
      stage2: data,
    };
    setResultSet(s);

    window.setTimeout(() => {
      navigate(url);
    }, 0);
  };

  const swapAnswer = (i: number, j: number) => {
    const tmp = [...step1Answers];
    const a = tmp[i].order;
    const b = tmp[j].order;
    if (a > b && a === priorityBorder) {
      setPriorityBorder(priorityBorder + 1);
      return;
    }
    if (a < b && a === priorityBorder - 1) {
      setPriorityBorder(priorityBorder - 1);
      return;
    }
    if (a < 0 || b < 0 || a >= step1Answers.length || b >= step1Answers.length)
      return;
    tmp[i].order = b;
    tmp[j].order = a;
    setStep1Answers(tmp);
  };

  // 重視する条件の一番下へ持ってくる
  const activeAnswer = (id: number) => {
    const tmp = [...step1Answers];
    const order = tmp[id].order;

    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].order < order) {
        if (tmp[i].order >= priorityBorder) tmp[i].order++;
      }
    }

    tmp[id].order = priorityBorder;

    setStep1Answers(tmp);
    setPriorityBorder(priorityBorder + 1);
  };

  // 重視しない条件の一番上へ持ってくる
  const disableAnswer = (id: number) => {
    const tmp = [...step1Answers];
    const order = tmp[id].order;

    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].order < order) {
        if (tmp[i].order >= priorityBorder) tmp[i].order++;
      }
    }

    tmp[id].order = priorityBorder - 1;

    setStep1Answers(tmp);
    setPriorityBorder(priorityBorder - 1);
  };

  const deleteStep1Answer = (id: number) => {
    const tmp = [...step1Answers];
    const order = tmp[id].order;
    tmp.splice(id, 1);
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].id > id) tmp[i].id--;
      if (tmp[i].order > order) tmp[i].order--;
    }
    setStep1Answers(tmp);
    if (priorityBorder > order) setPriorityBorder(priorityBorder - 1);
  };

  return (
    <Wrapper>
      <TimeGauge
        timerKey={trainingId + '-program3-stage2'}
        duration={900}
        stageName={InstructionText[3].title}
        color={InstructionColor}
        logo={InstructionLogo}
        onSkip={() => goTo(`/${trainingId}/program3/result`)}
        onBack={() => {
          navigate(`/${trainingId}/stages`);
        }}
      />
      <Step1
        title='他人の回答を見て班分けした条件を推測し、班分けの条件を追加・変更してください。'
        answers={step1Answers}
        onAdd={() => {
          setStep1Answers([
            ...step1Answers,
            {
              id: step1Answers.length,
              order: step1Answers.length,
              condition: '',
              reason: '',
            },
          ]);
        }}
        onSetStep1Answers={s => {
          const _s = [...s];
          setStep1Answers(_s);
        }}
        onDeleteStep1Answer={id => {
          deleteStep1Answer(id);
        }}
      />
      <Step2
        title='入力した条件の中で優先度を決めてください。'
        answers={step1Answers}
        priorityBorder={priorityBorder}
        onSwapAnswer={(a, b) => swapAnswer(a, b)}
        onActiveAnswer={a => activeAnswer(a)}
        onDisableAnswer={a => disableAnswer(a)}
      />
      <ButtonWrapper>
        <MainButton
          color='positive'
          size='large'
          onClick={() => setFinished(true)}
        >
          送信
        </MainButton>
      </ButtonWrapper>

      {finished && (
        <FinishWindow
          message='実習終了です。お疲れさまでした。'
          color={InstructionColor.primary}
          onClick={() => goTo(`/${trainingId}/program3/result`)}
        />
      )}
    </Wrapper>
  );
};

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const Wrapper = styled.div`
  height: 80rem;
  height: 100%;
  padding: 4rem;
  overflow-y: scroll;
  flex: 4;

  h2 {
    margin: 0;
    padding: 0;
    font-size: 2.5rem;
    font-weight: 600;
    margin-top: 1rem;
  }

  li {
    list-style: none;
  
`;
export default Program3Answer;
