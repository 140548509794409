import { CommonBG, CountDown } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useStatePersist as useState } from 'use-state-persist';

import { Program2State } from './Program2Answer';

interface CountDownProps {}
const Program2CountDown: React.FC<CountDownProps> = () => {
  const location = useLocation();
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet] = useState<Program2State>(
    `program2-${trainingId}-resultSet`,
    location.state as Program2State
  );

  const params = useParams();

  if (params.stage == null) {
    return null;
  }

  if (isNaN(parseInt(params.stage))) {
    return null;
  }

  return (
    <CommonBG>
      <CountDown
        redirect={`/${trainingId}/program2/game/${parseInt(params.stage)}`}
        state={resultSet}
      />
    </CommonBG>
  );
};

export default Program2CountDown;
