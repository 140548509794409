import { useSignIn } from '@riddler-co-jp/specc-auth-client';
import React from 'react';
import { Navigate, Route, Routes, useMatch, useParams } from 'react-router-dom';

import FinishPage from './components/eventProvider/common/FinishPage';
import Loading from './components/eventProvider/common/Loading';
import SelectStage from './components/eventProvider/common/SelectStage';
import StartPage from './components/eventProvider/common/StartPage';
import Program1CountDown from './components/eventProvider/stagePages/program1/Program1CountDown';
import Program1Game from './components/eventProvider/stagePages/program1/Program1Game';
import Program1Result from './components/eventProvider/stagePages/program1/Program1Result';
import Program1Rule from './components/eventProvider/stagePages/program1/Program1Rule';
import Program2CountDown from './components/eventProvider/stagePages/program2/Program2CountDown';
import Program2Game from './components/eventProvider/stagePages/program2/Program2Game';
import Program2Result from './components/eventProvider/stagePages/program2/Program2Result';
import Program2Rule from './components/eventProvider/stagePages/program2/Program2Rule';
import Program3CountDown from './components/eventProvider/stagePages/program3/Program3CountDown';
import Program3Result from './components/eventProvider/stagePages/program3/Program3Result';
import Program3Rule from './components/eventProvider/stagePages/program3/Program3Rule';
import Program3Game1 from './components/eventProvider/stagePages/program3/stage1/Program3Game';
import Program3Game2 from './components/eventProvider/stagePages/program3/stage2/Program3Game';
import { app } from '.';

interface ConditionedRouteRule {
  condition: boolean;
  redirectPathOnFail: string;
}
interface ConditionedRouteProps {
  rules: ConditionedRouteRule[];
}

const ConditionedRoute: React.FC<ConditionedRouteProps> = props => {
  for (const rule of props.rules) {
    if (!rule.condition) {
      return <Navigate to={rule.redirectPathOnFail} />;
    }
  }

  return <>{props.children}</>;
};

const debug = true;

export const TrainingRouter = () => {
  const { trainingId } = useParams<'trainingId'>();
  const match = useMatch('/:trainingId/*');
  const [signedIn, signInLoading] = useSignIn(
    app,
    'program',
    trainingId,
    match?.pathname === match?.pathnameBase ||
      match?.pathname === `${match?.pathnameBase}/`
  );
  const onlyValidUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    console.log(signInLoading, signedIn);
    return [
      {
        condition: debug || (!signInLoading && signedIn),
        redirectPathOnFail: '/auth-error',
      },
    ];
  }, [signInLoading, signedIn]);

  const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
    event.returnValue =
      'このページを再読み込みしますか？入力した内容は保存されません。';
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnloadEvent);
    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
  }, []);

  const onExit = (url: string) => {
    window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
    window.location.href = url;
  };

  if (signInLoading) {
    return <Loading />;
  }

  const page = match?.pathname.split('/') ?? [];
  if (['program1', 'program2', 'program3'].includes(page[2])) {
    const currentPage = page.slice(3).join('/');
    if (currentPage !== '' && !currentPage.match(/introduction/)) {
      localStorage.setItem('currentpage-' + page[2], currentPage);
    }
  }

  return (
    <Routes>
      {/* program1 */}
      <Route
        path='/program1/introduction'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program1Rule />
          </ConditionedRoute>
        }
      />
      <Route
        path='/program1/game'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program1Game />
          </ConditionedRoute>
        }
      />
      <Route
        path='/program1/result'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program1Result />
          </ConditionedRoute>
        }
      />
      <Route
        path='/program1/countdown'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program1CountDown />
          </ConditionedRoute>
        }
      />
      {/* program2 */}
      <Route
        path='/program2/introduction/:stage'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program2Rule />
          </ConditionedRoute>
        }
      />
      <Route
        path='/program2/game/:stage'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program2Game />
          </ConditionedRoute>
        }
      />
      <Route
        path='/program2/result'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program2Result />
          </ConditionedRoute>
        }
      />
      <Route
        path='/program2/countdown/:stage'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program2CountDown />
          </ConditionedRoute>
        }
      />
      {/* program3 */}
      <Route
        path='/program3/introduction'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program3Rule />
          </ConditionedRoute>
        }
      />
      <Route
        path='/program3/game/1'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program3Game1 />
          </ConditionedRoute>
        }
      />
      <Route
        path='/program3/game/2'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program3Game2 />
          </ConditionedRoute>
        }
      />
      <Route
        path='/program3/result'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program3Result />
          </ConditionedRoute>
        }
      />
      <Route
        path='/program3/countdown/1'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program3CountDown redirect='/program3/game/1' />
          </ConditionedRoute>
        }
      />
      <Route
        path='/program3/countdown/2'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Program3CountDown redirect='/program3/game/2' />
          </ConditionedRoute>
        }
      />
      <Route
        path='/'
        element={
          signInLoading ? (
            <Loading />
          ) : (
            <ConditionedRoute rules={onlyValidUserRules}>
              <StartPage />
            </ConditionedRoute>
          )
        }
      />
      <Route
        path='/stages'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <SelectStage />
          </ConditionedRoute>
        }
      />
      <Route
        path='/finish'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <FinishPage onExit={onExit} />
          </ConditionedRoute>
        }
      />
    </Routes>
  );
};
