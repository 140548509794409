import { CheckBox, CommonBG, Result } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import { Program1State } from '../../stagePages/program1/Program1Answer';

interface RuleInstrctionProps {
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

const Program3Rule: React.FC<RuleInstrctionProps> = () => {
  const initialData: Program1State = {
    step1: [[], [], []],
    step2: ['(未回答)', '(未回答)', '(未回答)', '(未回答)'],
    step3: '(未回答)',
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { trainingId } = useParams<'trainingId'>();

  const state = location.state as Program1State;

  const resultSet: Program1State = location.state ? state : initialData;

  const text = InstructionText[1];
  const checkTitle: string[] = ['父', '母', '息子', '娘'];

  return (
    <CommonBG>
      <Result
        title={text.title}
        mission={text.mission}
        video={text.video_result}
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      >
        <Text1>火元を触っていそうな人</Text1>
        <Step1List>
          <li>
            <ListTitle>
              <p>コンロ</p>
            </ListTitle>
            <ListContent>
              {checkTitle.map((s, i) => {
                return (
                  <CheckBox
                    disabled
                    value={resultSet.step1[0][i]}
                    key={`step1-0-${i}`}
                    id={`step1-0-${i}`}
                    label={s}
                  />
                );
              })}
            </ListContent>
          </li>
          <li>
            <ListTitle>
              <p>ストーブ</p>
            </ListTitle>
            <ListContent>
              {checkTitle.map((s, i) => {
                return (
                  <CheckBox
                    disabled
                    value={resultSet.step1[1][i]}
                    key={`step1-1-${i}`}
                    id={`step1-1-${i}`}
                    label={s}
                  />
                );
              })}
            </ListContent>
          </li>
          <li>
            <ListTitle>
              <p>タバコ</p>
            </ListTitle>
            <ListContent>
              {checkTitle.map((s, i) => {
                return (
                  <CheckBox
                    disabled
                    value={resultSet.step1[2][i]}
                    key={`step1-2-${i}`}
                    id={`step1-2-${i}`}
                    label={s}
                  />
                );
              })}
            </ListContent>
          </li>
        </Step1List>
        <Text1>火元の候補についての考察</Text1>
        <Text1>コンロ</Text1>
        {resultSet.step2[0] ? (
          <Text2>{resultSet.step2[0]}</Text2>
        ) : (
          <Text2 style={{ color: 'rgba(0,0,0,0.4)' }}>(未回答)</Text2>
        )}
        <Text1>ストーブ</Text1>
        {resultSet.step2[1] ? (
          <Text2>{resultSet.step2[1]}</Text2>
        ) : (
          <Text2 style={{ color: 'rgba(0,0,0,0.4)' }}>(未回答)</Text2>
        )}
        <Text1>タバコ</Text1>
        {resultSet.step2[2] ? (
          <Text2>{resultSet.step2[2]}</Text2>
        ) : (
          <Text2 style={{ color: 'rgba(0,0,0,0.4)' }}>(未回答)</Text2>
        )}
        <Text1>その他</Text1>
        {resultSet.step2[3] ? (
          <Text2>{resultSet.step2[3]}</Text2>
        ) : (
          <Text2 style={{ color: 'rgba(0,0,0,0.4)' }}>(未回答)</Text2>
        )}
        <Text1>最も可能性が高いと思われる火事の原因</Text1>
        {resultSet.step3 ? (
          <Text2>{resultSet.step3}</Text2>
        ) : (
          <Text2 style={{ color: 'rgba(0,0,0,0.4)' }}>(未回答)</Text2>
        )}
      </Result>
    </CommonBG>
  );
};

const Text1 = styled.p`
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Text2 = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  background: rgba(206, 212, 218, 0.25);
  padding: 12px 18px;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
`;

const Step1List = styled.ul`
  list-style: none;
  margin-bottom: 30px;

  li {
    margin-bottom: 6px;
    display: flex;
    width: 100%;
    align-items: center;
  }
`;

const ListTitle = styled.div`
  height: 48px;
  min-width: 80px;
  flex: 1;
  color: white;
  background: ${InstructionColor.secondary};
  border: 1px solid ${InstructionColor.secondary};
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

const ListContent = styled.div`
  background: white;
  height: 48px;
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #ced4da;
  border-left: none;

  padding: 0 10px;

  font-size: 1.8rem;
  white-space: nowrap;

  > div {
    margin: 0 12px;
  }
`;

export default Program3Rule;
