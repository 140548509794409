import { answer1, answer2 } from './Question.json';

export const GetQuestion = (step3: number[][] | undefined): any => {
  if (step3 == undefined) return answer1;

  let answer = answer1;
  let _match = true;
  step3.map((s, x) => {
    s.map((ss, y) => {
      if (!answer[x].answers.includes(ss)) _match = false;
    });
  });
  if (_match) answer = answer2;

  return answer;
};
