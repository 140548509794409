import * as React from 'react';
import styled from 'styled-components';

import Program1QuestionImg1 from '../../../../static/jpg/stage2/Program1_documents_1.jpg';
import Program1QuestionImg2 from '../../../../static/jpg/stage2/Program1_documents_2.jpg';
import Program1QuestionImg3 from '../../../../static/jpg/stage2/Program1_documents_3.jpg';
import Program1QuestionImg4 from '../../../../static/jpg/stage2/Program1_documents_4.jpg';

interface Stage2QuestionProps {}

const Stage2Question: React.FC<Stage2QuestionProps> = () => {
  return (
    <Stage2QuestionWrapper>
      <Question src={Program1QuestionImg1} />
      <Question src={Program1QuestionImg2} />
      <Question src={Program1QuestionImg3} />
      <Question src={Program1QuestionImg4} />
    </Stage2QuestionWrapper>
  );
};

const Question = styled.img`
  width: 100%;
  display: block;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 8rem;
  }
`;

const Stage2QuestionWrapper = styled.div`
  background: rgba(200, 200, 200, 0.7);
  height: 80rem;
  height: 100%;
  padding: 4rem;
  overflow-y: scroll;
  flex: 4;
`;

export default Stage2Question;
