import { FinishWindow, MainButton } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../../lib/instructionSettings';
import TimeGauge from '../../../../uiElements/timeGauge/TimeGauge';
import Step1 from '../Program3Step1';
import Step2 from '../Program3Step2';
import Step3 from '../Program3Step3';
import { Item, Program3State, tuple } from '../Types';

interface Program3AnswerProps {}

const Program3Answer: React.FC<Program3AnswerProps> = props => {
  const { trainingId } = useParams<'trainingId'>();
  const [finished, setFinished] = useState<boolean>(
    `program3-${trainingId}-stage1-finished`,
    false
  );
  const navigate = useNavigate();
  const [priorityBorder, setPriorityBorder] = useState<number>(
    `program3-${trainingId}-stage1-priorityBorder`,
    0
  );
  const [step1Answers, setStep1Answers] = useState<tuple[]>(
    `program3-${trainingId}-stage1-step1Answers`,
    [{ id: 0, order: 0, condition: '', reason: '' }]
  );
  const [step3Answers, setStep3Answers] = useState<number[][]>(
    `program3-${trainingId}-stage1-step3Answers`,
    [[], [], []]
  );

  const goTo = (url: string) => {
    const data: Item = {
      step1: step1Answers,
      priorityBorder: priorityBorder,
      step3: step3Answers,
    };

    const s: Program3State = {
      stage1: data,
      stage2: null,
    };

    window.setTimeout(() => {
      navigate(url, { state: s });
    }, 0);
  };

  const swapAnswer = (i: number, j: number) => {
    const tmp = [...step1Answers];
    const a = tmp[i].order;
    const b = tmp[j].order;
    if (a > b && a === priorityBorder) {
      setPriorityBorder(priorityBorder + 1);
      return;
    }
    if (a < b && a === priorityBorder - 1) {
      setPriorityBorder(priorityBorder - 1);
      return;
    }
    if (a < 0 || b < 0 || a >= step1Answers.length || b >= step1Answers.length)
      return;
    tmp[i].order = b;
    tmp[j].order = a;
    setStep1Answers(tmp);
  };

  // 重視する条件の一番下へ持ってくる
  const activeAnswer = (id: number) => {
    const tmp = [...step1Answers];
    const order = tmp[id].order;

    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].order < order) {
        if (tmp[i].order >= priorityBorder) tmp[i].order++;
      }
    }

    tmp[id].order = priorityBorder;

    setStep1Answers(tmp);
    setPriorityBorder(priorityBorder + 1);
  };

  // 重視しない条件の一番上へ持ってくる
  const disableAnswer = (id: number) => {
    const tmp = [...step1Answers];
    const order = tmp[id].order;

    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].order < order) {
        if (tmp[i].order >= priorityBorder) tmp[i].order++;
      }
    }

    tmp[id].order = priorityBorder - 1;

    setStep1Answers(tmp);
    setPriorityBorder(priorityBorder - 1);
  };

  const deleteStep1Answer = (id: number) => {
    const tmp = [...step1Answers];
    const order = tmp[id].order;
    tmp.splice(id, 1);
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].id > id) tmp[i].id--;
      if (tmp[i].order > order) tmp[i].order--;
    }
    setStep1Answers(tmp);
    if (priorityBorder > order) setPriorityBorder(priorityBorder - 1);
  };

  return (
    <Wrapper>
      <TimeGauge
        timerKey={trainingId + `-program3-stage1`}
        duration={1200}
        stageName={InstructionText[3].title}
        color={InstructionColor}
        logo={InstructionLogo}
        onSkip={() => goTo(`/${trainingId}/program3/countdown/2`)}
        onBack={() => {
          navigate(`/${trainingId}/stages`);
        }}
      />
      <Step1
        title='班分けの条件を列挙してください。'
        answers={step1Answers}
        onAdd={() => {
          setStep1Answers([
            ...step1Answers,
            {
              id: step1Answers.length,
              order: step1Answers.length,
              condition: '',
              reason: '',
            },
          ]);
        }}
        onSetStep1Answers={s => {
          const _s = [...s];
          setStep1Answers(_s);
        }}
        onDeleteStep1Answer={id => {
          deleteStep1Answer(id);
        }}
      />
      <Step2
        title='列挙した条件の中で優先度を決めてください。'
        answers={step1Answers}
        priorityBorder={priorityBorder}
        onSwapAnswer={(a, b) => swapAnswer(a, b)}
        onActiveAnswer={a => activeAnswer(a)}
        onDisableAnswer={a => disableAnswer(a)}
      />
      <Step3
        answer={step3Answers}
        onSetAnswer={(team: number, id: number) => {
          const tmp = [...step3Answers];

          tmp.map((single, i) => {
            single.map((inner, n) => {
              if (inner == id) {
                tmp[i].splice(n, 1);
              }
            });
          });
          if (team != -1) {
            tmp[team].push(id);
          }

          setStep3Answers(tmp);
        }}
      />
      <ButtonWrapper>
        <MainButton
          color='positive'
          size='large'
          onClick={() => setFinished(true)}
          disabled={
            !(
              step3Answers[0].length == 3 &&
              step3Answers[1].length == 3 &&
              step3Answers[2].length == 3
            )
          }
        >
          送信
        </MainButton>
      </ButtonWrapper>

      {finished && (
        <FinishWindow
          message='次は他の参加者の回答から、班分けの条件を推測してください。'
          color={InstructionColor.primary}
          onClick={() => goTo(`/${trainingId}/program3/countdown/2`)}
        />
      )}
    </Wrapper>
  );
};

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const Wrapper = styled.div`
  height: 80rem;
  height: 100%;
  padding: 4rem;
  overflow-y: scroll;
  flex: 4;

  h2 {
    margin: 0;
    padding: 0;
    font-size: 2.5rem;
    font-weight: 600;
    margin-top: 1rem;
  }

  li {
    list-style: none;
  }
`;

const Text3 = styled.a`
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 1rem;
`;

export default Program3Answer;
