import {
  CommonBG,
  Result,
  ScoreText,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import Label_1 from '../../../../static/svg/Label_Trial_1.svg';
import Label_2 from '../../../../static/svg/Label_Trial_2.svg';
import Label_3 from '../../../../static/svg/Label_Trial_3.svg';
import Label_0 from '../../../../static/svg/Label_Tutorial.svg';
import { Item, LabelLose, LabelWin, Program2State } from './Program2Answer';
import { stages } from './Question.json';

interface RuleInstrctionProps {
  //stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

const Program2Result: React.FC<RuleInstrctionProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet, setResultSet] = useState<any>(
    `program2-${trainingId}-resultSet`
  );

  const Labels = [Label_0, Label_1, Label_2, Label_3];
  const text = InstructionText[2];

  const totalRate =
    resultSet.total.win == 0
      ? 0
      : Math.ceil(
          (resultSet.total.win / (resultSet.total.win + resultSet.total.lose)) *
            100
        );

  return (
    <CommonBG>
      <Result
        title={text.title}
        mission={text.mission}
        video={text.video_result}
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      >
        <ResultWrapper>
          {resultSet && (
            <>
              {resultSet.results && (
                <>
                  <h2>総合結果</h2>
                  <li>
                    <TotalScore>
                      <li>
                        <Rate />
                      </li>
                      <li>
                        <LabelWin />
                      </li>
                      <li>
                        <LabelLose />
                      </li>
                    </TotalScore>
                    <Scores>
                      <li>
                        <TotalScore>
                          <li>
                            <Percent>
                              <ScoreText
                                value={totalRate}
                                size={60}
                                color='#343A40'
                              />
                              <Per />
                            </Percent>
                          </li>
                          <li>
                            <ScoreText
                              value={resultSet.total.win}
                              size={60}
                              color='#F6493C'
                            />
                          </li>
                          <li>
                            <ScoreText
                              value={resultSet.total.lose}
                              size={60}
                              color='#3C81F6'
                            />
                          </li>
                        </TotalScore>
                      </li>
                    </Scores>
                  </li>
                  <h2>ステージごとの勝敗</h2>
                  {resultSet.results.map((single: Item[], i: number) => {
                    if (single.length == 0) return null;
                    return (
                      <li key={i}>
                        <SingleScore>
                          <li>{<img src={Labels[single[0].stage]} />}</li>
                          <li>
                            <LabelWin />
                          </li>
                          <li>
                            <LabelLose />
                          </li>
                        </SingleScore>
                        <Scores>
                          {single
                            .filter((single2: Item, n: number) => {
                              return single2.stage == i;
                            })
                            .map((single2: Item, n: number) => {
                              return (
                                <li key={n}>
                                  <SingleScore>
                                    <li>
                                      <Question>
                                        <ScoreText
                                          value={single2.question + 1}
                                          size={30}
                                          color='#343A40'
                                        />
                                        <span></span>
                                        <ScoreText
                                          value={
                                            stages[single2.stage].questions
                                              .length
                                          }
                                          size={30}
                                          color='#343A40'
                                        />
                                      </Question>
                                    </li>
                                    <li>
                                      <ScoreText
                                        value={single2.win}
                                        size={30}
                                        color='#F6493C'
                                      />
                                    </li>
                                    <li>
                                      <ScoreText
                                        value={single2.lose}
                                        size={30}
                                        color='#3C81F6'
                                      />
                                    </li>
                                  </SingleScore>
                                </li>
                              );
                            })}
                        </Scores>
                      </li>
                    );
                  })}
                </>
              )}
            </>
          )}
        </ResultWrapper>
      </Result>
    </CommonBG>
  );
};

const SingleScore = styled.ul`
  display: flex;
  justify-content: center;

  > li {
    position: relative;
    margin: 4px 10px;
  }

  > li:nth-child(1) {
    width: 216px;
  }
  > li:nth-child(2) {
    width: 171px;
  }
  > li:nth-child(3) {
    width: 171px;
  }
`;

const TotalScore = styled.ul`
  display: flex;
  justify-content: center;

  > li {
    position: relative;
    margin: 4px 10px;
  }

  > li:nth-child(1) {
    width: 216px;
  }
  > li:nth-child(2) {
    width: 171px;
  }
  > li:nth-child(3) {
    width: 171px;
  }
`;

const ResultWrapper = styled.div`
  h2 {
    max-width: 620px;
    margin: 20px auto;
    font-size: 2.8rem;
  }
  li {
    list-style: none;
  }
  > li {
    background: rgba(206, 212, 218, 0.25);
    max-width: 600px;
    margin: 10px auto;
    padding: 10px;
  }
`;

const Scores = styled.ul`
  > li {
    position: relative;
  }
`;

const Question = styled.div`
  display: flex;
  align-items: baseLine;

  >ul: first-child {
    margin-bottom: -20px;
  }

  span {
    display: block;
    background: #343a40;
    width: 8px;
    height: 28px;
    transform: skew(-20deg, 0deg);
    margin: 0 10px;
    padding-bottom: 4px;
  }
`;

const Percent = styled.div`
  display: flex;
  align-items: baseLine;
  > svg {
    bottom: 6px;
    position: relative;
    margin-left: 4px;
  }
`;

const Rate = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='216'
      height='30'
      viewBox='0 0 216 30'
    >
      <g transform='translate(-43 -575)'>
        <g transform='translate(-7.395 574.938)'>
          <g transform='translate(50.395 0.063)'>
            <rect width='168' height='30' fill='#343a40' />
            <rect
              width='20'
              height='30'
              transform='translate(172)'
              fill='#343a40'
            />
            <rect
              width='20'
              height='30'
              transform='translate(196)'
              fill='#343a40'
            />
          </g>
        </g>
        <path
          d='M-20.68-3.12V-8.14A3.627,3.627,0,0,1-22-8.72h1.32v-3.5h-3.14c-.02-.12-.06-.24-.08-.36h3.22v-3.6h-.38l.1-.64H-23.7l-.08.64h-.58c.02-.24.04-.48.04-.72h-3.82v.72h-.54a5.205,5.205,0,0,1-.06-.72h-2.38c.02.26.04.48.06.72h-.48v3.6h2.6l-.06.36h-2.54v3.5h1.02a2.246,2.246,0,0,1-1.36.8v-8.74h-7.1v9.7a2.169,2.169,0,0,1-.36,1.48V1.68A4.46,4.46,0,0,0-36.4-1.08V1.68h1.68c2.02,0,2.84-.56,2.84-2.92V-3.6c.14-.02.3-.06.44-.08v.5h1.14a1.419,1.419,0,0,1-1.26.86V1.94c2.7-.3,4.16-1.16,4.88-3.22v3h3.04c1.2,0,2.62-.28,2.62-2.56v-2.4A3.238,3.238,0,0,0-20.68-3.12ZM-25-6.1h-1.1c0-.18.02-.34.02-.54h-.84a4.052,4.052,0,0,0,.66-.88A10.347,10.347,0,0,0-25-6.1Zm-.48,3.54a.215.215,0,0,1-.22.24h-.72a8.6,8.6,0,0,0,.14-.86h.8Zm-9.54-8.42h-.6v-.96h.6Zm0,3.66h-.6v-1h.6Zm0,3.56a.414.414,0,0,1-.42.38h-.36c.06-.44.1-.9.12-1.34h.66ZM-.7.86V-3.88h-.32V-7.56l-2.64-.38,2.64-.38v-3.56l-4.1.7V-6.5L-5.6-9.34H-7.32l2.08-1.86h-3.1l1.18-.68H-.7V-16.5H-7.34v-.48h-5.4v.48h-6.6v4.62h6.14l-1.34.94v2.58h.84l-.84.74v3.26h1.8v.48h-4.28a6.724,6.724,0,0,0,2.14-.68v-6.62l-4.12-.7v3.56l2.38.34a12.353,12.353,0,0,1-2.36.26v3.84h-.36V.86h6.6v.86h5.4V.86Zm-.5-4.74H-7.34v-.36h2.6l-.06-.4Zm-7.1-4.2a2.469,2.469,0,0,1-.8.16h-.34l1.02-.88Zm.44,3.72H-12.1a13.574,13.574,0,0,0,4.16-.5Z'
          transform='translate(197 597)'
          fill='#fff'
        />
      </g>
    </svg>
  );
};

const Per = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27.99'
      height='25.649'
      viewBox='0 0 27.99 25.649'
    >
      <path
        d='M28.89-10.02a5.8,5.8,0,0,0-.99-4.53,4.03,4.03,0,0,0-3.24-1.38H21.78a6.623,6.623,0,0,0-2.25.27l4.86-9.69H17.94L14.7-18.84a4.824,4.824,0,0,0-.81-4.05A5.02,5.02,0,0,0,9.96-24.3H7.38c-3.18,0-5.16,1.59-5.61,4.65l-.69,4.62a5.571,5.571,0,0,0,.99,4.47A3.942,3.942,0,0,0,5.28-9.15H8.16a7.652,7.652,0,0,0,1.71-.12L5.04.3h6.48l4.2-8.34L15.3-6.48a5.132,5.132,0,0,0,.78,4.26A4.938,4.938,0,0,0,20.01-.75h2.55c3.18,0,5.19-1.62,5.64-4.65Zm-5.49.66c-.06.21-.33,1.74-.36,1.92-.09.36-.21.48-.81.48h-.36a1.013,1.013,0,0,1-.63-.12.674.674,0,0,1-.09-.54A11.349,11.349,0,0,0,21.45-9c.15-.63.42-.81.81-.81h.57a.709.709,0,0,1,.48.12A.429.429,0,0,1,23.4-9.36ZM8.79-17.46c-.03.03-.27,1.38-.27,1.38-.12.63-.45.81-.84.81H7.14a.652.652,0,0,1-.48-.15c-.09-.06-.09-.21-.09-.33.03-.18.33-1.71.36-1.86.06-.39.21-.48.78-.48H8.1a1.334,1.334,0,0,1,.6.09C8.79-17.91,8.82-17.79,8.79-17.46Z'
        transform='translate(-0.988 25.35)'
        fill='#343a40'
      />
    </svg>
  );
};

export default Program2Result;
