import { CommonBG } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import Program3Answer from './Program3Answer';
import Program3Question from './Program3Question';

interface Program3GameProps {}

const Program3Game: React.FC<Program3GameProps> = () => {
  return (
    <CommonBG>
      <MainWrapper>
        <Wrapper>
          <Question>
            <Program3Question />
          </Question>
          <Program3Answer />
        </Wrapper>
      </MainWrapper>
    </CommonBG>
  );
};

const Question = styled.div`
  padding: 0 20px;
  background: rgba(206, 212, 218, 0.25);
  padding-top: 30px;
  margin-bottom: -80px;
  overflow-y: scroll;
`;

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 3rem);
  margin: 3rem auto 0;
  display: flex;
  overflow: hidden;

  min-width: 1200px;
  overflow-x: scroll;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 80px;
`;

export default Program3Game;
