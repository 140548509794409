import { CommonBG } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import Program1Answer from './Program1Answer';
import Program1Question from './Program1Question';

interface Stage2Props {}

const Stage2: React.FC<Stage2Props> = () => {
  return (
    <CommonBG>
      <MainWrapper>
        <Program1Question />
        <Program1Answer />
      </MainWrapper>
    </CommonBG>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 40px);
  margin: 40px auto 0;
  display: flex;
  overflow: hidden;
`;

export default Stage2;
