import {
  CommonBG,
  HeaderOptions,
  StageSelect,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  InstructionColor,
  InstructionLogo,
} from '../../../lib/instructionSettings';

const headerOptions: HeaderOptions = {
  hideHomeButton: true,
  hideSettingButton: true,
};

interface SelectStageProps {}
export const SelectStage: React.FC<SelectStageProps> = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  return (
    <CommonBG withHeader headerOptions={headerOptions} withFooter>
      <StageSelect
        onBack={() => navigate(`/${trainingId}/`)}
        onFinish={() => navigate(`/${trainingId}/finish`)}
        stages={[
          {
            name: '原因推理',
            path: '/' + trainingId + '/program1/introduction',
          },
          {
            name: 'ナンバーバトル',
            path: '/' + trainingId + '/program2/introduction/0',
          },
          {
            name: '班分け\nアブダクション',
            path: '/' + trainingId + '/program3/introduction',
          },
        ]}
        color={InstructionColor}
        logo={InstructionLogo}
      />
    </CommonBG>
  );
};
export default SelectStage;
