import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import { deleteData, hasData } from '../../../localStorage/LocalStorageManager';
import Dialog from '../../../uiElements/Dialog';
import { Program2State } from './Program2Answer';
import { RuleText } from './Program2RulePopup';

interface RuleInstrctionProps {
  //stageId: number;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const Program2Rule: React.FC<RuleInstrctionProps> = () => {
  const location = useLocation();
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet, setResultSet] = useState<Program2State>(
    `program2-${trainingId}-resultSet`
  );
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  const params = useParams();

  const navigate = useNavigate();
  const goTo = (url: string, params?: Program2State) => {
    if (params) {
      setResultSet(params);
      navigate(url);
    } else {
      navigate(url);
    }
  };

  const text = InstructionText[2];

  if (params.stage == null) {
    return null;
  }

  if (isNaN(parseInt(params.stage))) {
    return null;
  }

  const stage = parseInt(params.stage);
  console.log(stage);

  return (
    <CommonBG>
      <Rule
        title={text.title}
        mission={text.mission}
        description={text.description}
        video={text.video}
        onStart={() => {
          if (stage === 0) {
            if (!trainingId) {
              return;
            }
            if (hasData('program2', trainingId)) {
              setIsShowModal(true);
            } else {
              navigate(`/${trainingId}/program2/countdown/${stage}`);
            }
          } else {
            setResultSet(resultSet);
            goTo(`/${trainingId}/program2/countdown/${stage}`);
          }
        }}
        onBack={stage == 0 ? () => goTo(`/${trainingId}/stages`) : undefined}
        color={InstructionColor}
        logo={InstructionLogo}
      >
        {stage == 0 && (
          <Wrap>
            <RuleText />
          </Wrap>
        )}
      </Rule>
      {stage === 0 && (
        <Dialog
          message={
            '前回の進捗があります。続きから始めますか？\n新規に始める場合は「新規に始める」を選択してください。'
          }
          isShow={isShowModal}
          cancelText={'新規に始める'}
          onCancel={() => {
            if (!trainingId) {
              return;
            }
            deleteData('program2', trainingId);
            navigate(`/${trainingId}/program2/countdown/0`);
          }}
          onConfirm={() => {
            const currentPage =
              localStorage.getItem('currentpage-program2') ?? 'countdown1';
            navigate(`/${trainingId}/program2/${currentPage}`);
          }}
        />
      )}
    </CommonBG>
  );
};

const Wrap = styled.div`
  > div {
    overflow-y: auto;
    border-radius: 10px;

    p {
      background: none;
      padding: 0;
    }
  }
`;

export default Program2Rule;
