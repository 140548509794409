import { InstructionColors } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';

export const InstructionLogo: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='298.445'
      height='59.738'
      viewBox='0 0 298.445 59.738'
    >
      <g transform='translate(-490.778 -115)'>
        <path
          d='M35.881,19.117V16.528C35.881,7.886,31.42,4.7,24.849,4.7H4.14V37.2H15.968V30.546h8.8C32.535,30.546,35.881,25.927,35.881,19.117ZM23.973,17.882c0,1.434-.677,1.912-2.39,1.912H15.968v-4.46h5.615c1.752,0,2.39.239,2.39,2.19Zm41.1,5.894V13.183H52.448c-6.77,0-12.505,4.022-12.505,13.58V37.237h11.43V27c0-1.593,1.155-3.226,3.624-3.226Zm32.617,4.062V22.581c0-5.735-2.867-9.6-10.315-9.6H78.414c-7.248,0-10.514,3.465-10.514,9.717v4.938c0,6.372,3.186,9.8,10.514,9.8h8.961C94.822,37.436,97.689,33.613,97.689,27.838ZM86.936,25.728a1.421,1.421,0,0,1-1.593,1.553H80.325c-1.354,0-1.633-.637-1.633-1.553V24.692a1.451,1.451,0,0,1,1.633-1.513h5.018a1.44,1.44,0,0,1,1.593,1.513Zm45.6-8.323V9.36l-11.589,2.27v1.593H107.008c-5.177,0-5.695,1.832-5.695,4.938v.876c0,2.987.438,4.421,3.106,4.9v2.151c-3.584.637-4.142,2.429-4.142,5.217v1.115c0,4.54,1.553,5.615,7.567,5.615h15.691c5.5,0,7.169-1.553,7.169-5.615V31.3c0-2.987-1.513-5.456-7.169-5.456h-9.2V24.095h9c5.217,0,5.655-1.434,5.655-5.058v-1ZM117.4,18.679c0,.637-.319.916-1.314.916h-1.872c-1.035,0-1.274-.319-1.274-.916v-.04c0-.518.319-.876,1.274-.876h1.872c.956,0,1.314.358,1.314.876Zm1,13.3c0,.637-.159.916-1.274.916h-3.186c-.916,0-1-.279-1-.916v-.239c0-.358.119-.757,1-.757h3.186c1.035,0,1.274.319,1.274.757Zm41.856-8.2V13.183H147.63c-6.77,0-12.505,4.022-12.505,13.58V37.237h11.43V27c0-1.593,1.155-3.226,3.624-3.226ZM194.026,30.9V22.621c0-6.093-1.991-9.518-8.881-9.518h-20.59v8.164h16.727c1.115,0,1.474.438,1.474,1.274H170.967c-4.819,0-7.646,2.469-7.646,6.292v1.673c0,3.863,2.907,6.73,8.164,6.73H186.26C191.358,37.237,194.026,34.728,194.026,30.9Zm-11.47-1.434c0,.478-.2.677-.876.677h-6.014c-.757,0-.956-.279-.956-.677v-.319c0-.4.2-.637.956-.637h6.014c.637,0,.876.319.876.637Zm48.029,7.766V21.506c0-5.018-3.783-8.323-9.558-8.323h-14.3c-5.814,0-9.558,3.305-9.558,8.323V37.237h10.554V25.09c0-1.115.836-1.633,2.031-1.712V37.237H218V23.378c1.593,0,1.991.518,1.991,1.712V37.237Z'
          transform='translate(558.637 123.953)'
          fill='#fff'
        />
        <g transform='translate(490.778 115)'>
          <path
            d='M52.169,24.763H41.622a4.646,4.646,0,0,0,.571-2.217,4.567,4.567,0,1,0-8.536,2.217H23.11V35.3a4.567,4.567,0,1,1,2.21,8.569,4.46,4.46,0,0,1-2.21-.577V53.822H49.587A2.609,2.609,0,0,0,52.2,51.207Z'
            transform='translate(-7.771 -6.032)'
            fill='#fff'
          />
          <path
            d='M29.869,0A29.869,29.869,0,1,0,59.738,29.869,29.869,29.869,0,0,0,29.869,0Zm0,57.348A27.479,27.479,0,1,1,57.348,29.869,27.479,27.479,0,0,1,29.869,57.348Z'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  );
};

export const SpeccText = {
  title: 'Program',
  mission: '論理的に組み立てる力を身につける。',
  description_start: '説明(開始)',
  description_finish: '説明(終了)',
  video:
    'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
};

interface InstructionText {
  title: string;
  mission: string;
  description: string;
  video: string;
  video_result: string;
}

export const InstructionText: { [v: number]: InstructionText } = {
  1: {
    title: '原因推理',
    mission: '「論理的思考とは何か」を学ぶ。',
    description:
      'とある家で発生した火事について、家族の証言を聞いて火事の原因を推理しましょう。証言から論理的に状況を整理することで、「可能性が高い原因」を考えましょう。',
    video:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
    video_result:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
  },
  2: {
    title: 'ナンバーバトル',
    mission: '「論理の確からしさ」をもとに考える。',
    description:
      '数字が書かれたカードを使って、CPUとバトルを行います。手元にある2枚のカードのうち1枚を選び、複数のCPUと同時にカードを出し合います。カードの色が同一の場合数字が大きい方が勝利、異なる場合は小さい方が勝利になります。より勝率が上がる出し方を考えましょう。',
    video:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
    video_result:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
  },
  3: {
    title: '班分けアブダクション',
    mission: '「論理の優先度」を考え、推測する。',
    description:
      'あなたは小学校6年生の担任です。 授業でグループでの調べ学習を行うことになりました。児童たちは放課後や休日に集まって、図書館や街で調査を行うことになります。 調べ学習が上手くいくように、プロフィールを参考にしながら、9人の児童を3人×3班に分けてみましょう。',
    video:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
    video_result:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
  },
};

export const InstructionColor: InstructionColors = {
  primary: '#00d76e',
  secondary: '#00c355',
  finishButton: '#00ad3d',
  shadow: '#00A94A',
};
