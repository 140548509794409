import { CommonBG, Result } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import Stage3Icon0 from '../../../../static/png/stage3/2-3_image1.png';
import Stage3Icon1 from '../../../../static/png/stage3/2-3_image2.png';
import Stage3Icon2 from '../../../../static/png/stage3/2-3_image3.png';
import Stage3Icon3 from '../../../../static/png/stage3/2-3_image4.png';
import Stage3Icon4 from '../../../../static/png/stage3/2-3_image5.png';
import Stage3Icon5 from '../../../../static/png/stage3/2-3_image6.png';
import Stage3Icon6 from '../../../../static/png/stage3/2-3_image7.png';
import Stage3Icon7 from '../../../../static/png/stage3/2-3_image8.png';
import Stage3Icon8 from '../../../../static/png/stage3/2-3_image9.png';
import DetailWindow from './DetailWindow';
import { SingleUser } from './Program3Step3';
import { GetQuestion } from './Question';
import { question } from './Question.json';
import { Item, Program3State } from './Types';

interface RuleInstrctionProps {
  //stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

interface ResProps {
  data: Item;
}

const Program3Rule: React.FC<RuleInstrctionProps> = () => {
  const [currentFocus, setCurrentFocus] = React.useState<number>(-1);
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const location = useLocation();
  const [resultSet] = useState<Program3State>(
    `program3-${trainingId}-resultSet`,
    location.state as Program3State
  );

  const text = InstructionText[3];

  const answer = GetQuestion(resultSet?.stage1?.step3);

  const Step1Result = (props: ResProps) => {
    const sorted = [...props.data.step1].sort((a, b) => {
      return a.order - b.order;
    });

    const answersTop = sorted.slice(0, props.data.priorityBorder);
    const answersBottom = sorted.slice(
      props.data.priorityBorder,
      props.data.step1.length
    );

    return (
      <Step1Wrapper>
        <ul>
          <li></li>
          <li>班分けの条件</li>
          <li>根拠・補足</li>
        </ul>
        <p>重視する条件</p>
        <SingleCondition>
          {answersTop.length == 0 && (
            <li>
              <span>項目なし</span>
            </li>
          )}

          {answersTop.map((single, i) => {
            return (
              <li key={i}>
                <ul>
                  <li>{single.order + 1}</li>
                  {single.condition ? (
                    <li>{single.condition}</li>
                  ) : (
                    <li>
                      <span>(未記入)</span>
                    </li>
                  )}
                  {single.reason ? (
                    <li>{single.reason}</li>
                  ) : (
                    <li>
                      <span>(未記入)</span>
                    </li>
                  )}
                </ul>
              </li>
            );
          })}
        </SingleCondition>
        <p>重視しない条件</p>
        <SingleCondition>
          {answersBottom.length == 0 && (
            <li>
              <span>項目なし</span>
            </li>
          )}
          {answersBottom.map((single, i) => {
            return (
              <li key={i}>
                <ul>
                  <li>-</li>
                  {single.condition ? (
                    <li>{single.condition}</li>
                  ) : (
                    <li>
                      <span>(未記入)</span>
                    </li>
                  )}
                  {single.reason ? (
                    <li>{single.reason}</li>
                  ) : (
                    <li>
                      <span>(未記入)</span>
                    </li>
                  )}
                </ul>
              </li>
            );
          })}
        </SingleCondition>
      </Step1Wrapper>
    );
  };

  const getStudent = (id: number) => {
    let ret = null;
    question.map(single => {
      if (single.id == id) {
        ret = single;
      }
    });
    return ret;
  };

  const getIcon = (i: number) => {
    const iconList = [
      Stage3Icon0,
      Stage3Icon1,
      Stage3Icon2,
      Stage3Icon3,
      Stage3Icon4,
      Stage3Icon5,
      Stage3Icon6,
      Stage3Icon7,
      Stage3Icon8,
    ];
    return iconList[i];
  };

  return (
    <CommonBG>
      <Result
        title={text.title}
        mission={text.mission}
        video={text.video_result}
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      >
        {resultSet?.stage1 && (
          <Wrapper>
            <h2>あなたの回答</h2>
            <Step1Result data={resultSet.stage1} />
            <h2>あなたの結論</h2>
            <List>
              {resultSet?.stage1 &&
                resultSet.stage1.step3.map((single, i) => {
                  return (
                    <ListChild key={i}>
                      <div>
                        {i == 0 && <p>A班</p>}
                        {i == 1 && <p>B班</p>}
                        {i == 2 && <p>C班</p>}
                      </div>
                      <ul>
                        {single.map((inner, n) => {
                          const student: any = getStudent(inner);
                          if (student == null) return null;
                          return (
                            <li
                              key={n}
                              onClick={() => setCurrentFocus(student.id)}
                            >
                              <SingleUser>
                                <span>
                                  <img src={getIcon(student.id)} />
                                </span>
                                {student.title}
                                {student.call}
                              </SingleUser>
                            </li>
                          );
                        })}
                      </ul>
                    </ListChild>
                  );
                })}
            </List>
          </Wrapper>
        )}
        {resultSet?.stage2 && (
          <Wrapper>
            <h2>誰かの回答</h2>
            <List>
              {answer.map((single: any, i: number) => {
                return (
                  <ListChild key={i}>
                    <div>
                      <p>{single.name}</p>
                    </div>
                    <ul>
                      {single.answers.map((inner: any, n: number) => {
                        const student: any = getStudent(inner);
                        if (student == null) return null;
                        return (
                          <li
                            key={n}
                            onClick={() => setCurrentFocus(student.id)}
                          >
                            <SingleUser>
                              <span>
                                <img src={getIcon(student.id)} />
                              </span>
                              {student.title}
                              {student.call}
                            </SingleUser>
                          </li>
                        );
                      })}
                    </ul>
                  </ListChild>
                );
              })}
            </List>
            <h2>あなたの結論</h2>
            <Step1Result data={resultSet.stage2} />
          </Wrapper>
        )}
      </Result>
      {currentFocus != -1 && (
        <Detail
          onClick={() => {
            setCurrentFocus(-1);
          }}
        >
          <DetailWindow id={currentFocus} />
        </Detail>
      )}
    </CommonBG>
  );
};

const SingleCondition = styled.ul`
  span {
    text-align: center;
    width: 100%;

    display: block;
    font-size: 1.4rem;
    opacity: 0.5;
    margin-top: 4px;
    margin-bottom: 6px;
  }

  > li {
    font-size: 1.8rem;
    padding: 12px 0;

    ul {
      display: flex;
      white-space: pre-wrap;
      font-size: 1.6rem;

      > li:nth-child(1) {
        width: 80px;
        text-align: center;
      }
      > li:nth-child(2) {
        width: 300px;
        padding-right: 10px;
        span {
          text-align: left;
        }
      }
      > li:nth-child(3) {
        flex: 1;
        padding-right: 10px;
        span {
          text-align: left;
        }
      }
    }
  }
  > li:nth-child(2n-1) {
    background: white;
  }
`;

const Step1Wrapper = styled.div`
  background: #f3f4f6;
  border: 1px solid #ced4da;
  margin: 10px 0;

  > ul:first-child {
    display: flex;
    font-size: 1.8rem;
    font-weight: bold;
    padding: 10px 0;

    > li:nth-child(1) {
      width: 80px;
    }
    > li:nth-child(2) {
      width: 300px;
    }
    > li:nth-child(3) {
      flex: 1;
    }
  }

  p {
    background: #00c355;
    font-weight: bold;
    font-size: 1.8rem;
    color: white;
    text-align: center;
    padding-bottom: 2px;
  }
`;

const Detail = styled.div`
  > div {
    width: 100vw;
    height: 100vh;
  }
`;

const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -10px;
`;
const ListChild = styled.li`
  flex: 1;
  width: 100%;
  margin: 10px 10px;

  > div {
    width: 100%;
    background: #00c355;
    color: white;
    font-weight: bold;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
  }

  > ul {
    flex: 1;
    border: 2px solid #ced4da;
    border-top: none;
    height: 190px;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 6px 0;

    > li {
      margin: 2px 10px;
    }
  }
`;

const Wrapper = styled.div`
  margin: 20px 0;

  h2 {
    font-size: 2.8rem;
  }
  li {
    list-style: none;
  }
`;

export default Program3Rule;
