import {
  FinishButton,
  ScoreText,
  TextWindow,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import { timeActions } from '../../../../redux/actions/timeActions';
import Label_1 from '../../../../static/svg/Label_Trial_1.svg';
import Label_2 from '../../../../static/svg/Label_Trial_2.svg';
import Label_3 from '../../../../static/svg/Label_Trial_3.svg';
import Label_0 from '../../../../static/svg/Label_Tutorial.svg';
import Icon_lose from '../../../../static/svg/Lose.svg';
import Icon_win from '../../../../static/svg/Win.svg';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import { stages } from './Question.json';
import SingleCard from './SingleCard';

interface Stage2AnswerProps {
  stage: number;
  thinkingTime: number;
}

export const CardType = {
  Green: 'green',
  Red: 'red',
} as const;
export type CardType = typeof CardType[keyof typeof CardType];

export interface SingleCardProps {
  type: string;
  value: number;
}

interface CardProps {
  card: SingleCardProps;
  size: 'small' | 'large';
}

interface ResultCount {
  win: number;
  lose: number;
}

const Card: React.FC<CardProps> = props => {
  // todo: cast string to union
  let _type;
  if (props.card.type == 'red') {
    _type = CardType.Red;
  } else if (props.card.type == 'green') {
    _type = CardType.Green;
  }
  return <SingleCard type={_type} value={props.card.value} size={props.size} />;
};

const Stage2Answer: React.FC<Stage2AnswerProps> = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const goTo = (url: string, params: any) => {
    setResultSet(params);
    navigate(url, { state: params });
  };
  const [currentQuestion, setCurrentQuestion] = useState<number>(
    `program2-${trainingId}-currentQuestion`,
    0
  );
  const [selectedCard, setSelectedCard] = React.useState<
    'left' | 'right' | null
  >(null);
  const [finish, setFinish] = React.useState<boolean>(false);
  const [effectedCard, setEffectedCard] = React.useState<boolean[]>([]);
  const [resultCount, setResultCount] = useState(
    `program2-${trainingId}-resultCount`,
    { win: 0, lose: 0 }
  );
  const [result, setResult] = useState<boolean[]>(
    `program2-${trainingId}-result`,
    []
  );
  const [controllable, setControllable] = React.useState<boolean>(true);
  const [showFinished, setShowFinished] = React.useState<boolean>(false);
  const [effectRunning, setEffectRunning] = React.useState<boolean>(false);
  const [finishEffect, setFinishEffect] = React.useState<boolean>(false);
  const effectedCardRef = React.useRef(effectedCard);
  const setResultCountRef = React.useRef(setResultCount);
  const [timeOver, setTimeOver] = React.useState<boolean>(false);
  const [resultSet, setResultSet] = useState<any>(
    `program2-${trainingId}-resultSet`
  );

  const current = stages[props.stage].questions[currentQuestion];

  const Labels = [Label_0, Label_1, Label_2, Label_3];

  const location = useLocation();
  const [data, setData] = useState<Item[]>(`program2-${trainingId}-data`, []);

  const [total, setTotal] = useState(
    `program2-${trainingId}-total`,
    resultSet?.total || { win: 0, lose: 0 }
  );

  React.useEffect(() => {
    setTimeOver(false);
  }, []);

  React.useEffect(() => {
    setResultCountRef.current = setResultCount;
  }, [setResultCount]);

  React.useEffect(() => {
    effectedCardRef.current = effectedCard;
  }, [effectedCard]);

  React.useEffect(() => {
    if (selectedCard != null && timeOver == true) {
      // 結果
      let myAnswer = { type: '', value: 0 };
      let result: boolean[] = [];

      if (selectedCard == 'left') {
        myAnswer = current.Hand.left;
      } else {
        myAnswer = current.Hand.right;
      }

      result = current.fields.map(single => {
        const enemyAnswer =
          single.select == 'left' ? single.left : single.right;

        if (myAnswer.type == enemyAnswer.type) {
          // 同じ色
          return myAnswer.value > enemyAnswer.value;
        } else {
          // 違う色
          return myAnswer.value < enemyAnswer.value;
        }
      });

      const resultScore = result.reduce(reducer, { win: 0, lose: 0 });

      setResult(result);
      setFinish(true);
      setControllable(false);
      setEffectRunning(true);

      if (effectRunning == false) {
        const wait = (time: number) => {
          return new Promise((resolve, reject) => {
            setTimeout(resolve, time);
          });
        };

        // start effect
        const effect = async () => {
          await wait(800);

          for (let i = 0; i < result.length; i++) {
            const _effectedCard = [...effectedCardRef.current];
            _effectedCard.push(result[i]);
            setEffectedCard(_effectedCard);

            await wait(200);
          }

          await wait(500);

          for (
            let i = 0;
            i < Math.max(resultScore.win, resultScore.lose);
            i++
          ) {
            const _resultCount = {
              win: resultScore.win < i + 1 ? resultScore.win : i + 1,
              lose: resultScore.lose < i + 1 ? resultScore.lose : i + 1,
            };
            setResultCount(_resultCount);

            await wait(200);
          }

          await wait(800);

          setEffectRunning(false);
          setShowFinished(true);
        };

        effect();
      }
    }
  }, [selectedCard, timeOver]);

  if (stages.length <= props.stage) return null;

  const reducer = (a: ResultCount, b: boolean) => {
    if (b) {
      a.win++;
    } else {
      a.lose++;
    }
    return a;
  };

  const next = () => {
    const _data: Item = {
      stage: props.stage,
      question: currentQuestion,
      win: resultCount.win,
      lose: resultCount.lose,
    };
    data.push(_data);

    const _total = { win: 0, lose: 0 };
    _total.win = total.win + resultCount.win;
    _total.lose = total.lose + resultCount.lose;

    if (stages[props.stage].questions.length - 1 > currentQuestion) {
      setFinishEffect(true);
      setShowFinished(false);

      setTimeout(() => {
        setData(data);
        setTotal(_total);

        setFinishEffect(false);
        setControllable(true);
        setResult([]);
        setFinish(false);
        setCurrentQuestion(currentQuestion + 1);
        setSelectedCard(null);
        setTimeOver(false);
        setEffectedCard([]);
        setResultCount({ win: 0, lose: 0 });

        const startTimeString = '' + Date.now();
        window.localStorage.setItem(
          'timer:' + `${trainingId}-program2-${props.stage ?? 'general'}`,
          startTimeString
        );
        const startTime = new Date(parseInt(startTimeString));
        dispatch(
          timeActions.setTimer({
            startTime,
            duration: props.thinkingTime,
          })
        );
      }, 1000);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const results: Item[][] = resultSet?.results || [];
      results.push(data);
      setCurrentQuestion(0);

      const out: Program2State = {
        results: results,
        total: _total,
      };

      if (props.stage < stages.length - 1) {
        goTo(`/${trainingId}/program2/introduction/${props.stage + 1}`, out);
      } else {
        goTo(`/${trainingId}/program2/result`, out);
      }
    }
  };

  return (
    <Answer>
      <TimeGauge
        timerKey={`${trainingId}-program2-${props.stage}`}
        duration={props.thinkingTime}
        stageName={InstructionText[2].title}
        color={InstructionColor}
        logo={InstructionLogo}
        onFinished={() => {
          setTimeOver(true);
        }}
        onSkip={() => {
          next();
        }}
        onBack={() => {
          navigate(`/${trainingId}/stages`);
        }}
      />
      <Wrapper>
        <EnemyField finishEffect={finishEffect}>
          {current.fields.map((single, i) => {
            const finishEffect = effectedCard.length > i;
            return (
              <FieldCards key={i}>
                <FieldCardWrapper finished={finish}>
                  <Card card={single.left} size={'small'} />
                  <Card card={single.right} size={'small'} />
                </FieldCardWrapper>
                <AnswerCard finished={finish}>
                  <Card
                    card={single.select == 'left' ? single.left : single.right}
                    size={'small'}
                  />
                </AnswerCard>
                <ResultCard finished={finishEffect}>
                  <img src={result[i] ? Icon_win : Icon_lose} />
                </ResultCard>
              </FieldCards>
            );
          })}
        </EnemyField>
        <MyField>
          <Message controllable={controllable} timeOver={timeOver}>
            <TextWindow bracket>カードを選んでください</TextWindow>
          </Message>
          <MyCards finishEffect={finishEffect}>
            <MyCardStyle
              side='left'
              selected={selectedCard}
              controllable={controllable}
              onClick={() => {
                if (controllable) {
                  if (selectedCard == 'left') {
                    setSelectedCard(null);
                  } else {
                    setSelectedCard('left');
                  }
                }
              }}
            >
              <Card card={current.Hand.left} size={'large'} />
            </MyCardStyle>
            <MyCardStyle
              side='right'
              selected={selectedCard}
              controllable={controllable}
              onClick={() => {
                if (controllable) {
                  if (selectedCard == 'right') {
                    setSelectedCard(null);
                  } else {
                    setSelectedCard('right');
                  }
                }
              }}
            >
              <Card card={current.Hand.right} size={'large'} />
            </MyCardStyle>
          </MyCards>
          <Question>
            <Label src={Labels[props.stage]} />
            <div>
              <ScoreText
                value={currentQuestion + 1}
                size={60}
                color='#343A40'
              />
              <span></span>
              <ScoreText
                value={stages[props.stage].questions.length}
                size={30}
                color='#343A40'
              />
            </div>
          </Question>
          <Score finishEffect={false}>
            <ul>
              <li>
                <LabelWin />
                <ScoreText
                  value={total.win + resultCount.win}
                  size={60}
                  color='#F6493C'
                />
              </li>
              <li>
                <LabelLose />
                <ScoreText
                  value={total.lose + resultCount.lose}
                  size={60}
                  color='#3C81F6'
                />
              </li>
            </ul>
          </Score>
        </MyField>
      </Wrapper>
      <FinishButton
        finished={showFinished}
        color={InstructionColor}
        click={() => {
          next();
        }}
      >
        {props.stage < stages.length - 1 ||
        currentQuestion + 1 != stages[props.stage].questions.length
          ? '次のバトルに挑戦しましょう！'
          : '以上で実習は終了です。お疲れ様でした！'}
      </FinishButton>
    </Answer>
  );
};

export interface Item {
  stage: number;
  question: number;
  win: number;
  lose: number;
}

export interface Program2State {
  results: Item[][];
  total: any;
}

interface finishedCard {
  finished: boolean;
}

const AnswerCard = styled.div`
  transition: 0.2s;
  position: absolute;

  ${(p: finishedCard) => {
    return p.finished
      ? `
        opacity: 1.0;
        transform: rotate(20deg) scale(1.0) translate(100px, -20px);
      `
      : `opacity: 0.0;`;
  }}
`;

const FieldCardWrapper = styled.div`
  transition: 0.2s;
  display: flex;

  > div {
    margin: 10px;
  }
  > div:last-child {
    margin-left: 0px;
  }

  ${(p: finishedCard) => {
    return p.finished
      ? `
      opacity: 0.0;
    `
      : `
      opacity: 1.0;
    `;
  }}
`;

const ResultCard = styled.div`
  transition: 0.2s;
  position: absolute;
  left: -30px;

  img {
    pointer-events: none;
  }

  ${(p: finishedCard) => {
    return p.finished
      ? `
        opacity: 1.0;
        transform: scale(1.0);
      `
      : `
        opacity: 0.0;
        transform: scale(2.0);
      `;
  }}
`;

const Label = styled.img`
  margin-bottom: 10px;
`;

const Question = styled.div`
  user-select: none;
  position: absolute;
  left: 40px;
  bottom: 30px;

  > div:last-child {
    display: flex;
    align-items: baseLine;

    >ul: first-child {
      margin-bottom: -20px;
    }

    span {
      display: block;
      background: #343a40;
      width: 8px;
      height: 28px;
      transform: skew(-20deg, 0deg);
      margin: 0 10px;
      padding-bottom: 4px;
    }
  }
`;

const Score = styled.div`
  user-select: none;
  position: absolute;
  right: 0px;
  bottom: 30px;
  overflow-x: hidden;

  ul {
    margin: 10px 0;
    margin-right: 0;
    padding-right: 50px;

    transition: 1.2s;

    ${(p: MyCardsProps) => {
      return p.finishEffect
        ? `
        transform: translateX(400px);
      `
        : `
      `;
    }}
  }
`;

const Answer = styled.div`
  margin-top: 10px;
  width: 100%;
  overflow-y: scroll;

  li {
    list-style: none;
  }
`;
const Wrapper = styled.div`
  min-height: 720px;
  height: 100%;
`;

interface CardStyleProps {
  selected: 'left' | 'right' | null;
  side: 'left' | 'right';
  controllable: boolean;
}

const MyCardStyle = styled.div`
  ${(p: CardStyleProps) => {
    return `
      transform: rotate(${p.side == 'left' ? '-10deg' : '10deg'});

      ${p.selected != p.side && p.selected != null ? `opacity: 0.4;` : ''}

      ${
        p.selected == p.side && p.side == 'left'
          ? `transform: scale(1.1) rotate(-14deg) translateX(-20px) translateY(-20px) !important;`
          : ''
      }
      ${
        p.selected == p.side && p.side == 'right'
          ? `transform: scale(1.1) rotate(14deg) translateX(20px) translateY(-20px) !important;`
          : ''
      }
      
      ${
        p.selected != p.side
          ? `&:hover {
            opacity: 1.0;

            transform: scale(1.1) rotate(${
              p.side == 'left' ? '-10deg' : '10deg'
            });;
          }`
          : ``
      }
      
      ${
        p.controllable == false
          ? `
            pointer-events: none;
            ${
              p.selected == p.side && p.side == 'left'
                ? `
                  transform: scale(1.2) rotate(0) translateX(70px) translateY(-40px) !important;
                `
                : ``
            }
            ${
              p.selected == p.side && p.side == 'right'
                ? `
                  transform: scale(1.2) rotate(0) translateX(-70px) translateY(-40px) !important;
                `
                : ``
            }
            ${
              p.selected != p.side
                ? `
                  opacity: 0.0;
                `
                : ``
            }
          `
          : ``
      }
    `;
  }}
`;

interface MyCardsProps {
  finishEffect: boolean;
}

const MyCards = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  > div {
    transition: 0.2s;
    cursor: pointer;
  }

  transition: 1s;

  ${(p: MyCardsProps) => {
    return p.finishEffect ? `transform: translatey(300px);` : '';
  }}
`;

const FieldCards = styled.li`
  display: flex;
  background: rgba(206, 212, 218, 0.25);
  border-radius: 16px;
  position: relative;
  margin: 10px 30px;
`;

const EnemyField = styled.ul`
  margin: 0 90px;
  height: calc(100% - 300px);
  width: calc(100% - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  transition: 1s;

  ${(p: MyCardsProps) => {
    return p.finishEffect ? `opacity: 0` : '';
  }}
`;
const MyField = styled.div`
  height: 300px;
  width: 100%;
  overflow: hidden;
`;

interface MessageProps {
  controllable: boolean;
  timeOver: boolean;
}

const Message = styled.div`
  text-align: center;
  margin-top: 2px;
  transition: 0.2s;

  > div {
    display: inline;
    padding: 10px 40px;
  }

  ${(p: MessageProps) => {
    return `
      ${
        p.controllable
          ? `
        opacity: 1.0;
      `
          : `
        opacity: 0.0;
      `
      }
    `;
  }}

  >div {
    display: inline-block;
    min-height: initial;
    min-width: initial;
  }

  ${(p: MessageProps) => {
    return `
      ${
        p.timeOver
          ? `
            @keyframes scaleAnim {
              0% {
                transform: scale(1);
              }
              80% {
                transform: scale(1);
              }
              85% {
                transform: scale(0.9);
              }
              90% {
                transform: scale(1.05);
              }
              100% {
                transform: scale(1);
              }
            }
            > div {
              animation-name: scaleAnim;
              animation-duration: 1.8s;
              animation-iteration-count: infinite;
            }
        
            
            color: red;
            > div::before,
            > div::after {
              border-color: red !important;
            }
      `
          : `
      `
      }
    `;
  }}
`;

export const LabelLose = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='171'
      height='30'
      viewBox='0 0 171 30'
    >
      <g transform='translate(-1069 -575)'>
        <g transform='translate(1018.605 574.938)'>
          <g transform='translate(0 0)'>
            <g transform='translate(50.395 0.063)'>
              <rect width='123' height='30' fill='#3c81f6' />
              <rect
                width='20'
                height='30'
                transform='translate(127)'
                fill='#3c81f6'
              />
              <rect
                width='20'
                height='30'
                transform='translate(151)'
                fill='#3c81f6'
              />
            </g>
          </g>
        </g>
        <path
          d='M-55.56.22V-5.14h-7.98a1.328,1.328,0,0,1-1.5-1.38V-16.1h-6.14V-5.82c0,3.94,2.14,6.04,6.46,6.04Zm18.6-6.94V-9.18c0-5.48-1.84-7.02-7.18-7.02h-2.1c-5.76,0-7.1,1.54-7.1,7.02v2.46c0,5.5,1.34,7.02,7.1,7.02h2.1C-38.4.3-36.96-1.22-36.96-6.72Zm-5.96-.2c0,1.12-.42,1.9-1.88,1.9h-.78c-1.46,0-1.88-.5-1.88-1.9V-9.08c0-1.42.7-1.88,1.88-1.88h.78c1.46,0,1.88.74,1.88,1.88Zm24.1,2.14V-5.82c0-2.4-1.74-4.2-5.06-4.2h-4.44c-.36,0-.46-.2-.46-.44v-.08c0-.22.14-.38.46-.38h8.42V-16.1h-9.46c-3.58,0-5.68,1.4-5.68,5.04v.58c0,3.14,1.6,4.8,5.64,4.8h3.98c.26,0,.4.1.4.38v.06c0,.26-.1.38-.4.38h-9.3V.2H-24.2C-20.3.2-18.82-2.12-18.82-4.78Zm17.64,5V-4.9H-9.52c-1.12,0-1.28-.36-1.28-1.1h8.9V-9.84h-8.88a1.082,1.082,0,0,1,1.26-1.1h8.34V-16.1h-9.4c-5.32,0-6.2,2.88-6.2,7.02v3.1c0,3.68,1.38,6.2,6.14,6.2Z'
          transform='translate(1184 597)'
          fill='#fff'
        />
      </g>
    </svg>
  );
};

export const LabelWin = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='171'
      height='30'
      viewBox='0 0 171 30'
    >
      <g transform='translate(-1069 -459)'>
        <g transform='translate(1018.605 458.938)'>
          <g transform='translate(0 0)'>
            <g transform='translate(50.395 0.063)'>
              <rect width='123' height='30' fill='#f6493c' />
              <rect
                width='20'
                height='30'
                transform='translate(127)'
                fill='#f6493c'
              />
              <rect
                width='20'
                height='30'
                transform='translate(151)'
                fill='#f6493c'
              />
            </g>
          </g>
        </g>
        <path
          d='M-29.94-6.64v-9.5h-5.52v9.28c0,1.3-.34,1.64-1.28,1.7V-16.14H-41.2V-5.16c-1-.08-1.3-.5-1.3-1.7v-9.28h-5.56v9.5C-48.06-1.6-46.12.2-41.24.2h4.26C-32.12.2-29.94-1.9-29.94-6.64ZM-21.32.22V-16.1h-6.16V.22ZM-1.24-4.64V-16.06H-6.78v9.02c0,.22-.08.28-.16.28-.12,0-.16-.06-.2-.28l-2-5.78a4.438,4.438,0,0,0-4.54-3.38c-2.7,0-4.64,1.66-4.64,4.94V.22h5.52v-9c0-.18.04-.26.12-.26.06,0,.12.06.16.24l1.92,5.14C-9.74-1.36-8.38.4-5.8.4-2.98.4-1.24-1.14-1.24-4.64Z'
          transform='translate(1184 481)'
          fill='#fff'
        />
      </g>
    </svg>
  );
};

export default Stage2Answer;
