import { CommonBG } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Program2Answer from './Program2Answer';
import Program2RulePopup from './Program2RulePopup';
import { stages } from './Question.json';

interface Stage2Props {}

export const thinkingTime = [60, 120, 120, 120];

const Stage2: React.FC<Stage2Props> = () => {
  const params = useParams();

  if (params.stage == null) {
    return null;
  }

  if (isNaN(parseInt(params.stage))) {
    return null;
  }

  if (stages.length <= parseInt(params.stage)) return null;

  const stage = parseInt(params.stage);

  return (
    <CommonBG>
      <MainWrapper>
        <Program2RulePopup />
        <Program2Answer thinkingTime={thinkingTime[stage]} stage={stage} />
      </MainWrapper>
    </CommonBG>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 3rem);
  margin: 3rem auto 0;
  display: flex;
  overflow: hidden;
`;

export default Stage2;
