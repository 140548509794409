import * as React from 'react';
import styled from 'styled-components';

import P1 from '../../../../static/jpg/howto/p1.jpg';
import P2 from '../../../../static/jpg/howto/p2.jpg';
import P3 from '../../../../static/jpg/howto/p3.jpg';

interface Program2RulePopupProps {}

export const RuleText = () => {
  return (
    <Inner>
      <Single>
        <div>
          <Img src={P1} />
        </div>
        <div>
          <h2>①どちらか好きな方を選択</h2>
          <p>プレイヤーは配られた2枚のカードのうち、1枚を選択します。</p>
        </div>
      </Single>

      <Single>
        <div>
          <Img src={P2} />
        </div>
        <div>
          <h2>②選んだカードで１対１のバトル</h2>
          <p>
            自分が選択したカードと、CPUが選択したカードで【1対1】のバトルを行います。
          </p>
          <p>
            CPUが複数いる場合は、選択した方のカードで1人ずつバトルの勝敗をつけます。
          </p>
        </div>
      </Single>

      <Single>
        <div>
          <Img src={P3} />
        </div>
        <div>
          <h2>③カードの色に応じて勝負が決まり、勝敗数がカウントされる</h2>
          <p>
            自分と相手のカードの色が同じ場合、数が大きい方の勝ちになります。
          </p>
          <p>
            自分と相手のカードの色が違う場合、数が小さい方の勝ちになります。
          </p>
          <p>数が同じ場合は色に関わらず引き分けとなります。</p>
        </div>
      </Single>

      <Note>
        <li>※同じ数字の場合は色に関わらず引き分けになります。</li>
        <li>※CPU も、より多くの相手に勝つように想定してカードを選びます。</li>
      </Note>
    </Inner>
  );
};

const Program2RulePopup: React.FC<Program2RulePopupProps> = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Wrapper>
      <Bg
        isOpen={isOpen}
        onClick={() => {
          if (isOpen) setIsOpen(false);
        }}
      />
      <Content isOpen={isOpen}>
        <Tab
          isOpen={isOpen}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <TabText />
        </Tab>
        <RuleText />
      </Content>
    </Wrapper>
  );
};

interface WrapperProps {
  isOpen: boolean;
}

const Note = styled.div`
  background: #dddddd;
  font-size: 1.6rem;
  padding: 10px 14px;
  border-radius: 8px;
  list-style: none;

  li {
    margin-bottom: 2px;
  }
`;

const Single = styled.div`
  display: flex;
  margin: 20px 0;

  div:first-child {
    flex: 1;
    margin-right: 30px;
    max-width: 420px;
  }

  div:last-child {
    flex: 1;
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  border: 2px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  pointer-events: none;
  overflow: hidden;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;

const Bg = styled.div`
  transition: 0.2s;

  ${(p: WrapperProps) => {
    return p.isOpen
      ? `
    background: rgba(0,0,0,0.6);
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: auto;
  `
      : `
      `;
  }}
`;

const Content = styled.div`
  z-index: 3;
  position: relative;
  height: calc(100% - 140px);
  width: calc(100% - 200px);
  margin-left: 50px;
  margin-top: 60px;
  transition: 0.4s;

  ${(p: WrapperProps) => {
    return p.isOpen
      ? `
    `
      : `
      transform: translateX(calc(-100% - 50px));
      `;
  }}
`;

const Inner = styled.div`
  h2 {
    font-size: 2.6rem;
    margin-bottom: 10px;
  }
  p {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  pointer-events: auto;
  overflow-y: scroll;
  position: relative;
  z-index: 3;
  background: white;
  border: 4px solid #00d572;
  border-radius: 10px 0 10px 10px;
  padding: 20px 30px;
  height: 100%;
`;

const Tab = styled.div`
  pointer-events: auto;
  z-index: 2;
  margin-top: 0px;
  right: -54px;
  width: 30px;
  height: 156px;
  border-left: none;
  padding: 12px;
  border-radius: 0 10px 10px 0;
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  background: #00d572;
  position: absolute;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);

  ${(p: WrapperProps) => {
    return p.isOpen
      ? `
    `
      : `
      `;
  }}
`;

const TabText = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28.29'
      height='154.94'
      viewBox='0 0 28.29 154.94'
    >
      <path
        d='M-.84-6.07v0ZM-19.56-4V-16.24H-27.3V-3.22c0,2.88-.42,3.45-1.62,3.75V9.86C-22.26,9.32-19.56,5.93-19.56-4ZM-.84-6.07C-.84,4.76-3.81,9.86-10.89,9.86h-7.47v-26.1h7.92V-.82L-10.59.71h.36c.36,0,1.47-1.08,1.47-6.72v-.06ZM-12.16,37.92V14.98h-7.71V37.92ZM-.84,53.93v0ZM-19.56,56V43.76H-27.3V56.78c0,2.88-.42,3.45-1.62,3.75v9.33C-22.26,69.32-19.56,65.93-19.56,56ZM-.84,53.93c0,10.83-2.97,15.93-10.05,15.93h-7.47V43.76h7.92V59.18l-.15,1.53h.36c.36,0,1.47-1.08,1.47-6.72v-.06ZM-17.49,80.19V76.65H-28.44v3.54Zm16.47,24v-7.8a1.164,1.164,0,0,1-.96.3h-.06c-.15,0-.21-.06-.21-.27V94.74H-1.2V79.32h-.96l.63-2.7H-8.04l-.57,2.7h-.81a19.889,19.889,0,0,0-.57-2.7H-16.5a14.269,14.269,0,0,1,.6,2.7h-1.02v1.35H-29.13v5.94h12.21v8.13h1.53c-.33,1.11-.9,1.47-1.53,1.56v8.31c5.4-.66,7.38-3.72,7.74-9.87h.36v5.94c0,2.7,1.32,3.9,4.02,3.9h1.5A4.179,4.179,0,0,0-1.02,104.19ZM-17.49,90.48V87.15H-28.44v3.33Zm0,3.9V90.99H-28.44v3.39Zm0,10.2V94.89H-28.44v9.69Zm9.18-16.56H-9.9v-1.8h1.59Zm-14.07,12.3h-1.11V99.24h1.11Zm21.27,33.85V110.86H-16.56v13.98c0,1.26-.12,1.89-.57,2.22V138.7a8.292,8.292,0,0,0,6.66-6.09v5.91h5.04C-2.58,138.52-1.11,137.2-1.11,134.17Zm-16.5,3.36v-26.4H-28.8v26.4Zm9.36-18.63H-9.72v-1.26h1.47Zm0,6.09H-9.72v-1.5h1.47Zm0,5.97c0,.18-.06.36-.24.36h-1.62a17.414,17.414,0,0,0,.27-1.92h1.59Zm-14.4-9.87h-.93v-3.24h.93Zm0,9.93h-.93v-3.39h.93Z'
        transform='translate(29.13 16.24)'
        fill='#fff'
      />
    </svg>
  );
};

export default Program2RulePopup;
