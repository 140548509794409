import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import { deleteData, hasData } from '../../../localStorage/LocalStorageManager';
import Dialog from '../../../uiElements/Dialog';

interface RuleInstrctionProps {
  //stageId: number;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const Program1Rule: React.FC<RuleInstrctionProps> = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();

  const text = InstructionText[1];
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);

  return (
    <CommonBG>
      <Rule
        title={text.title}
        mission={text.mission}
        description={text.description}
        video={text.video}
        onStart={() => {
          if (!trainingId) {
            return;
          }
          if (hasData('program1', trainingId)) {
            console.log('hasData', 'program1', trainingId);
            setIsShowModal(true);
          } else {
            console.log('has no Data', 'program1', trainingId);
            navigate(`/${trainingId}/program1/countdown`);
          }
        }}
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      />
      <Dialog
        message={
          '前回の進捗があります。続きから始めますか？\n新規に始める場合は「新規に始める」を選択してください。'
        }
        isShow={isShowModal}
        cancelText={'新規に始める'}
        onCancel={() => {
          if (!trainingId) {
            return;
          }
          deleteData('program1', trainingId);
          navigate(`/${trainingId}/program1/countdown`);
        }}
        onConfirm={() => {
          const currentPage =
            localStorage.getItem('currentpage-program1') ?? 'countdown1';
          navigate(`/${trainingId}/program1/${currentPage}`);
        }}
      />
    </CommonBG>
  );
};

export default Program1Rule;
