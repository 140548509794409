import { id } from 'date-fns/locale';
import * as React from 'react';
import styled from 'styled-components';

import NoteImg from '../../../../static/png/note.png';
import NoteBgImg from '../../../../static/png/noteBg.png';
import Stage3Icon0 from '../../../../static/png/stage3/2-3_image1.png';
import Stage3Icon1 from '../../../../static/png/stage3/2-3_image2.png';
import Stage3Icon2 from '../../../../static/png/stage3/2-3_image3.png';
import Stage3Icon3 from '../../../../static/png/stage3/2-3_image4.png';
import Stage3Icon4 from '../../../../static/png/stage3/2-3_image5.png';
import Stage3Icon5 from '../../../../static/png/stage3/2-3_image6.png';
import Stage3Icon6 from '../../../../static/png/stage3/2-3_image7.png';
import Stage3Icon7 from '../../../../static/png/stage3/2-3_image8.png';
import Stage3Icon8 from '../../../../static/png/stage3/2-3_image9.png';
import { question } from './Question.json';

interface DetailWindowProps {
  id: number;
}

const DetailWindow: React.FC<DetailWindowProps> = props => {
  const iconList = [
    Stage3Icon0,
    Stage3Icon1,
    Stage3Icon2,
    Stage3Icon3,
    Stage3Icon4,
    Stage3Icon5,
    Stage3Icon6,
    Stage3Icon7,
    Stage3Icon8,
  ];
  return (
    <DetailWrapper>
      <Detail>
        <Person>
          <img src={iconList[props.id]} />
          <div>
            <h3>
              {question[props.id].title}
              <i>{question[props.id].call}</i>
            </h3>
          </div>
        </Person>
        <p>{question[props.id].detail}</p>
        <Note />
      </Detail>
    </DetailWrapper>
  );
};

const Person = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 68px;
    height: 68px;
    margin-right: 10px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.16);
  }

  h3 {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  p {
    font-size: 1.6rem;
  }

  i {
    font-size: 1.6rem;
    font-style: initial;
    margin-left: 4px;
  }
`;

const DetailWrapper = styled.div`
  position: fixed;
  width: calc(100vw - 508px);
  height: calc(100vh - 40px);
  bottom: 0;
  right: 0;
  background: rgba(52, 58, 64, 0.9);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Detail = styled.div`
  background: white;
  max-width: 600px;
  padding: 40px 60px;

  > div {
    align-items: flex-end;

    h3 {
      font-size: 5.2rem;
      margin-bottom: 0;
      i {
        font-size: 3rem;
      }
    }
    img {
      width: 108px;
      height: 108px;
      box-shadow: none;
      margin-right: 20px;
    }
  }

  p {
    margin-top: 20px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: left !important;
    line-height: 3rem;
  }

  position: relative;

  background-image: url(${NoteBgImg});
  background-repeat: repeat;
  background-size: 31px 31px;
`;

const Note = styled.div`
  position: absolute;
  height: calc(100% - 30px);
  width: 37px;
  left: -13px;
  top: 15px;

  background-image: url(${NoteImg});
  background-repeat: no-repeat repeat;
  background-size: 37px 31px;
`;

export default DetailWindow;
