import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Stage3Icon0 from '../../../../static/png/stage3/2-3_image1.png';
import Stage3Icon1 from '../../../../static/png/stage3/2-3_image2.png';
import Stage3Icon2 from '../../../../static/png/stage3/2-3_image3.png';
import Stage3Icon3 from '../../../../static/png/stage3/2-3_image4.png';
import Stage3Icon4 from '../../../../static/png/stage3/2-3_image5.png';
import Stage3Icon5 from '../../../../static/png/stage3/2-3_image6.png';
import Stage3Icon6 from '../../../../static/png/stage3/2-3_image7.png';
import Stage3Icon7 from '../../../../static/png/stage3/2-3_image8.png';
import Stage3Icon8 from '../../../../static/png/stage3/2-3_image9.png';
import IconStep3 from '../../../../static/png/step3.png';
import { question } from './Question.json';

interface Step3Props {
  answer: number[][];
  onSetAnswer: (team: number, id: number) => void;
}

const getIcon = (i: number) => {
  const iconList = [
    Stage3Icon0,
    Stage3Icon1,
    Stage3Icon2,
    Stage3Icon3,
    Stage3Icon4,
    Stage3Icon5,
    Stage3Icon6,
    Stage3Icon7,
    Stage3Icon8,
  ];
  return iconList[i];
};

const Program3Step3: React.FC<Step3Props> = props => {
  const [mousePos, setMousePos] = useState<number[]>([0, 0]);
  const [drugging, setDrugging] = useState<number>(-1);
  const [currentHover, setCurrentHover] = useState<number>(-1);
  const students = question;

  const drugRef = useRef(-1);
  drugRef.current = drugging;

  const FieldRef0 = useRef<HTMLDivElement | null>(null);
  const FieldRef1 = useRef<HTMLDivElement | null>(null);
  const FieldRef2 = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  });

  const isHover = (obj: HTMLDivElement, x: number, y: number) => {
    const rect = obj.getBoundingClientRect();
    if (x > rect.left && y > rect.top && x < rect.right && y < rect.bottom) {
      return true;
    } else {
      return false;
    }
  };

  const onMouseMove = (event: MouseEvent) => {
    if (drugRef.current == -1) return;
    const { clientX, clientY } = event;
    setMousePos([clientX, clientY]);

    let onHover = -1;

    if (FieldRef0.current) {
      if (isHover(FieldRef0.current, clientX, clientY)) onHover = 0;
    }
    if (FieldRef1.current) {
      if (isHover(FieldRef1.current, clientX, clientY)) onHover = 1;
    }
    if (FieldRef2.current) {
      if (isHover(FieldRef2.current, clientX, clientY)) onHover = 2;
    }

    if (onHover != currentHover) {
      setCurrentHover(onHover);
    }
  };

  const onMouseUp = (event: MouseEvent) => {
    if (drugRef.current == -1) return;
    if (drugging != -1) {
      if (currentHover != -1 && props.answer[currentHover].length >= 3) {
        props.onSetAnswer(-1, drugging);
      } else {
        props.onSetAnswer(currentHover, drugging);
      }
    }
    setDrugging(-1);
    setCurrentHover(-1);
  };

  const InputGroups = students.map(student => {
    let exist = false;
    props.answer.map(single => {
      single.map(inner => {
        if (inner == student.id) exist = true;
      });
    });

    return (
      <li key={student.id}>
        <SingleUser
          onMouseDown={event => {
            setDrugging(student.id);
            const { clientX, clientY } = event;
            setMousePos([clientX, clientY]);
          }}
          style={
            drugging == student.id || exist
              ? { opacity: 0, pointerEvents: 'none' }
              : {}
          }
        >
          <span>
            <img src={getIcon(student.id)} />
          </span>
          {student.title}
          {student.call}
        </SingleUser>
      </li>
    );
  });

  const RenderField: React.FC<{ field: number }> = p => {
    return (
      <Box>
        {props.answer[p.field].map((single, i) => {
          return (
            <SingleUser
              key={i}
              style={
                drugging == single ? { opacity: 0, pointerEvents: 'none' } : {}
              }
            >
              <span>
                <img src={getIcon(single)} />
              </span>
              {students[single].title}
              {students[single].call}
              <RemoveUser
                onClick={e => {
                  props.onSetAnswer(-1, single);
                  e.preventDefault();
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </RemoveUser>
              <Touch
                onMouseDown={event => {
                  setDrugging(single);
                  const { clientX, clientY } = event;
                  setMousePos([clientX, clientY]);
                }}
              ></Touch>
            </SingleUser>
          );
        })}
      </Box>
    );
  };

  return (
    <Wrapper>
      <Step src={IconStep3} />
      <h2>優先度を考慮して実際に班分けしてください。</h2>

      <Users>{InputGroups}</Users>

      <Fields>
        <Field
          ref={r => (FieldRef0.current = r)}
          hover={currentHover == 0 && props.answer[0].length < 3}
        >
          <p>A班</p>
          <RenderField field={0} />
        </Field>
        <Field
          ref={r => (FieldRef1.current = r)}
          hover={currentHover == 1 && props.answer[1].length < 3}
        >
          <p>B班</p>
          <RenderField field={1} />
        </Field>
        <Field
          ref={r => (FieldRef2.current = r)}
          hover={currentHover == 2 && props.answer[2].length < 3}
        >
          <p>C班</p>
          <RenderField field={2} />
        </Field>
      </Fields>

      {drugging != -1 && (
        <DruggingItem
          style={{
            left: mousePos[0],
            top: mousePos[1],
          }}
        >
          <SingleUser>
            <span>
              <img src={getIcon(students[drugging].id)} />
            </span>
            {students[drugging].title}
            {students[drugging].call}
          </SingleUser>
        </DruggingItem>
      )}
    </Wrapper>
  );
};
const Touch = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  z-index: 2;
`;

const RemoveUser = styled.button`
  margin-left: auto;
  padding: 4px 4px;
  font-size: 2rem;
  color: #868e96;
  transition: 0.2s;
  z-index: 4;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: #ea0e49;
  }
`;

const DruggingItem = styled.div`
  position: fixed;
  z-index: 99;
  transform: translate(-50%, -50%);
`;

export const SingleUser = styled.div`
  position: relative;
  border-radius: 99px;
  border: 2px solid #969696;
  font-size: 1.6rem;
  font-weight: bold;
  background: white;
  padding: 4px 10px;
  padding-left: 5px;
  margin: 4px 8px;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  line-height: 3rem;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  button {
    opacity: 0;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }

  span {
    margin-right: 8px;
    width: 38px;
    height: 38px;
    border-radius: 99px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      transform: translateY(5px);
      width: 52px;
      height: 52px;
    }
  }
`;

const Users = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  background: rgba(206, 212, 218, 0.25);
  margin: 20px 0;
`;

const Fields = styled.div`
  display: flex;
  margin: 0 -6px;
  justify-content: center;
`;

interface FieldProps {
  hover: boolean;
}

const Field = styled.div`
  flex: 1;
  max-width: 225px;
  margin: 0 6px;
  p {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 1.8rem;
    background: #00c355;
    padding-bottom: 2px;
  }

  transition: 0.2s;

  ${(p: FieldProps) => {
    if (p.hover) {
      return `transform: scale(1.1)`;
    }
  }};
`;

const Box = styled.div`
  border: 2px solid #ced4da;
  border-top: none;
  height: 200px;
  background: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 10px 0;

  > div {
    margin: 8px 8px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const Step = styled.img`
  display: block;
  margin-bottom: 20px;
  margin-left: -40px;
  height: 42px;
  width: auto;
`;
export default Program3Step3;
