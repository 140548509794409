import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import IconStep2 from '../../../../static/png/step2.png';
import { tuple } from './Types';

interface Step2Props {
  answers: tuple[];
  priorityBorder: number;
  onSwapAnswer: (a: number, b: number) => void;
  onActiveAnswer: (a: number) => void;
  onDisableAnswer: (a: number) => void;
  title: string;
}

interface ListProps {
  data: tuple;
  isBottom: boolean;
  prevId: number;
  nextId: number;
}

const Program3Step2: React.FC<Step2Props> = props => {
  const ListContent = (listProps: ListProps) => {
    return (
      <li key={listProps.data.id}>
        <Sort>
          <SortButton
            disabled={listProps.data.order == 0 && props.priorityBorder != 0}
            onMouseDown={() => {
              if (listProps.isBottom) {
                props.onActiveAnswer(listProps.data.id);
              } else {
                props.onSwapAnswer(listProps.data.id, listProps.prevId);
              }
            }}
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </SortButton>

          {props.priorityBorder <= listProps.data.order ? (
            <p style={{ opacity: 0.4 }}>-</p>
          ) : (
            <p>{listProps.data.order + 1}</p>
          )}

          <SortButton
            disabled={listProps.isBottom}
            onMouseDown={() => {
              if (
                props.priorityBorder - 1 == listProps.data.order ||
                listProps.data.order >= props.answers.length
              ) {
                props.onDisableAnswer(listProps.data.id);
              } else {
                props.onSwapAnswer(listProps.data.id, listProps.nextId);
              }
            }}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </SortButton>
        </Sort>
        {listProps.data.condition == '' ? (
          <None>(未記入)</None>
        ) : (
          <ContentText style={listProps.isBottom ? { opacity: 0.4 } : {}}>
            {listProps.data.condition}
          </ContentText>
        )}
        {listProps.data.reason == '' ? (
          <None>(未記入)</None>
        ) : (
          <ContentText style={listProps.isBottom ? { opacity: 0.4 } : {}}>
            {listProps.data.reason}
          </ContentText>
        )}
      </li>
    );
  };

  const sorted = [...props.answers].sort((a, b) => {
    return a.order - b.order;
  });

  const answersTop = sorted.slice(0, props.priorityBorder);
  const answersBottom = sorted.slice(
    props.priorityBorder,
    props.answers.length
  );

  return (
    <Wrapper>
      <Step src={IconStep2} />
      <h2>{props.title}</h2>
      <p>
        上記の条件全てを考慮しなくても大丈夫です。班分けに使用する条件のみ
        「重視する条件」に移動させてください。
      </p>

      <ListWrapper>
        <ul>
          <li>優先度</li>
          <li>班分けの条件</li>
          <li>根拠・補足</li>
        </ul>

        <Sep>重視する条件</Sep>

        <Answers>
          {answersTop.length == 0 ? (
            <NoContent>
              <p>項目なし</p>
            </NoContent>
          ) : (
            answersTop.map((single, i: number) => {
              const prevId = i <= 0 ? -1 : answersTop[i - 1].id;
              const nextId =
                i >= answersTop.length - 1 ? -1 : answersTop[i + 1].id;
              return (
                <ListContent
                  prevId={prevId}
                  nextId={nextId}
                  isBottom={false}
                  data={single}
                  key={i}
                />
              );
            })
          )}
        </Answers>

        <Sep>重視しない条件</Sep>

        <Answers>
          {answersBottom.length == 0 ? (
            <NoContent>
              <p>項目なし</p>
            </NoContent>
          ) : (
            answersBottom.map((single, i: number) => {
              return (
                <ListContent
                  prevId={-1}
                  nextId={-1}
                  isBottom={true}
                  data={single}
                  key={i}
                />
              );
            })
          )}
        </Answers>
      </ListWrapper>
    </Wrapper>
  );
};

interface ButtonProps {
  disabled: boolean;
}

const ContentText = styled.p`
  margin-top: 4px;
  white-space: pre-wrap;
`;

const None = styled.p`
  opacity: 0.4;
  text-align: center;
  width: 100%;
`;

const NoContent = styled.li`
  opacity: 0.4;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  padding: 0 !important;
`;

const SortButton = styled.div`
  cursor: pointer;
  font-size: 2rem;
  margin: -4px -5px;
  padding: 4px 10px;

  ${(p: ButtonProps) => {
    if (p.disabled) {
      return `
        opacity: 0.3;
        pointer-events: none;
      `;
    } else {
      return ``;
    }
  }}
`;

const Sep = styled.div`
  background: #00c355;
  color: white;
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
  padding-bottom: 2px;
`;

const ListWrapper = styled.div`
  width: 100%;
  background: #f3f4f6;
  border: 1px solid #ced4da;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  ul:first-child {
    padding: 10px 12px;
    display: flex;
    font-size: 1.6rem;
    font-weight: bold;

    li:nth-child(1) {
      width: 100px;
      margin-right: 10px;
    }
    li:nth-child(2) {
      width: 360px;
      margin-right: 10px;
    }
    li:nth-child(3) {
      flex: 1;
      margin-right: 10px;
    }
  }
`;

const Answers = styled.ul`
  min-height: 48px;

  p {
    font-size: 1.3rem !important;
    font-weight: initial !important;
  }

  li {
    display: flex;
    padding: 10px 12px;

    &:nth-child(2n-1) {
      background: white;
    }

    > div {
      width: 100px;
      margin-right: 10px;
    }
    > p:nth-child(2) {
      width: 360px;
      margin-right: 10px;
      text-align: left;
    }
    > p:nth-child(3) {
      flex: 1;
      margin-right: 10px;
      text-align: left;
    }
  }
`;

const Wrapper = styled.div`
  margin-bottom: 40px;

  p {
    font-size: 1.6rem;
    font-weight: bold;
  }

  > p {
    margin-top: 12px;
    margin-bottom: 18px;
  }
`;

const Sort = styled.div`
  display: flex;

  p {
    font-size: 1.8rem !important;
    font-weight: bold !important;
    padding: 0 10px !important;
    text-align: center;
    width: 16px;
  }
`;

const Step = styled.img`
  display: block;
  margin-bottom: 20px;
  margin-left: -40px;
  height: 42px;
  width: auto;
`;
export default Program3Step2;
