import { ScoreText } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import CardGreenLarge from '../../../../static/png/card_l_green.png';
import CardRedLarge from '../../../../static/png/card_l_red.png';
import CardGreenSmall from '../../../../static/png/card_s_green.png';
import CardRedSmall from '../../../../static/png/card_s_red.png';
import { CardType } from './Program2Answer';

interface SingleCardProps {
  type?: CardType;
  size: 'small' | 'large';
  value: number;
}

const SingleCard: React.FC<SingleCardProps> = props => {
  return (
    <Wrapper size={props.size}>
      <>
        {props.size == 'large' ? (
          <>
            {props.type == CardType.Green ? (
              <Step src={CardGreenLarge} />
            ) : (
              <Step src={CardRedLarge} />
            )}
          </>
        ) : (
          <>
            {props.type == CardType.Green ? (
              <Step src={CardGreenSmall} />
            ) : (
              <Step src={CardRedSmall} />
            )}
          </>
        )}
      </>

      <span>
        <ScoreText
          color={props.type == CardType.Green ? '#00c355' : '#FF6F30'}
          value={props.value}
          align='center'
          size={props.size == 'large' ? 60 : 42}
        />
      </span>
    </Wrapper>
  );
};

interface wrapperProps {
  size: string;
}

const Wrapper = styled.div`
  position: relative;

  span {
    position: absolute;
    user-select: none;
    top: 54px;
    width: 100%;
  }

  ${(p: wrapperProps) => {
    if (p.size == 'small') {
      return `
        width: 104px;
        height: 166px;
      `;
    } else {
      return `
        width: 166px;
        height: 246px;

        span {
          top: 84px;
        }
      `;
    }
  }}
`;

const Step = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

export default SingleCard;
