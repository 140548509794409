import {
  CheckBox,
  MainButton,
  Textarea,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import Step1 from '../../../../static/png/step1.png';
import Step2 from '../../../../static/png/step2.png';
import Step3 from '../../../../static/png/step3.png';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';

interface Stage2AnswerProps {}

export interface Program1State {
  step1: boolean[][];
  step2: string[];
  step3: string;
}

const Stage2Answer: React.FC<Stage2AnswerProps> = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const [text3, setText3] = useState<string>(
    `program1-${trainingId}-text3`,
    ''
  );
  const [text4, setText4] = useState<string>(
    `program1-${trainingId}-text4`,
    ''
  );
  const [text5, setText5] = useState<string>(
    `program1-${trainingId}-text5`,
    ''
  );
  const [text6, setText6] = useState<string>(
    `program1-${trainingId}-text6`,
    ''
  );
  const [text7, setText7] = useState<string>(
    `program1-${trainingId}-text7`,
    ''
  );
  const [konro, setKonro] = useState<boolean[]>(
    `program1-${trainingId}-konro`,
    [false, false, false, false]
  );
  const [stove, setStove] = useState<boolean[]>(
    `program1-${trainingId}-stove`,
    [false, false, false, false]
  );
  const [tobacco, setTobacco] = useState<boolean[]>(
    `program1-${trainingId}-tobacco`,
    [false, false, false, false]
  );

  const checkTitle: string[] = ['父', '母', '息子', '娘'];

  const changeCheck = (i: number, j: number) => {
    if (i == 0) {
      const tmp = [...konro];
      tmp[j] = !tmp[j];
      setKonro(tmp);
    } else if (i == 1) {
      const tmp = [...stove];
      tmp[j] = !tmp[j];
      setStove(tmp);
    } else if (i == 2) {
      const tmp = [...tobacco];
      tmp[j] = !tmp[j];
      setTobacco(tmp);
    }
  };

  const goTo = (url: string) => {
    const data: Program1State = {
      step1: [konro, stove, tobacco],
      step2: [text3, text4, text5, text6],
      step3: text7,
    };
    navigate(url, { state: data });
  };

  return (
    <Stage2AnswerWrapper>
      <TimeGauge
        timerKey={trainingId + `-program1answer`}
        duration={900}
        stageName={InstructionText[1].title}
        color={InstructionColor}
        logo={InstructionLogo}
        onSkip={() => goTo(`/${trainingId}/program1/result`)}
        onBack={() => {
          navigate(`/${trainingId}/stages`);
        }}
      />
      <Step src={Step1} />
      <Content>
        <Text1>
          以下の火元の候補について、触っていそうな人全員にチェックを入れてください。
        </Text1>
        <Step1List>
          <li>
            <ListTitle>
              <p>コンロ</p>
            </ListTitle>
            <ListContent>
              {checkTitle.map((s, i) => {
                return (
                  <CheckBox
                    key={`step1-0-${i}`}
                    id={`step1-0-${i}`}
                    label={s}
                    value={konro[i]}
                    onChange={() => changeCheck(0, i)}
                  />
                );
              })}
            </ListContent>
          </li>
          <li>
            <ListTitle>
              <p>ストーブ</p>
            </ListTitle>
            <ListContent>
              {checkTitle.map((s, i) => {
                return (
                  <CheckBox
                    key={`step1-1-${i}`}
                    id={`step1-1-${i}`}
                    label={s}
                    value={stove[i]}
                    onChange={() => changeCheck(1, i)}
                  />
                );
              })}
            </ListContent>
          </li>
          <li>
            <ListTitle>
              <p>タバコ</p>
            </ListTitle>
            <ListContent>
              {checkTitle.map((s, i) => {
                return (
                  <CheckBox
                    key={`step1-2-${i}`}
                    id={`step1-2-${i}`}
                    label={s}
                    value={tobacco[i]}
                    onChange={() => changeCheck(2, i)}
                  />
                );
              })}
            </ListContent>
          </li>
        </Step1List>
      </Content>

      <Step src={Step2} />
      <Content>
        <Text1>
          以下の火元の候補について、考察したことを自由に記入してください。
        </Text1>
        <Text2>
          誰がいつ触れたか、火事の原因になった可能性は高いか、など。
        </Text2>
        <Text3>コンロ</Text3>
        <Textarea
          maxLength={400}
          placeholder='火元について考察したことを入力してください。'
          value={text3}
          onChange={(val: string) => {
            setText3(val);
          }}
        />
        <Text3>ストーブ</Text3>
        <Textarea
          maxLength={400}
          placeholder='火元について考察したことを入力してください。'
          value={text4}
          onChange={(val: string) => {
            setText4(val);
          }}
        />
        <Text3>タバコ</Text3>
        <Textarea
          maxLength={400}
          placeholder='火元について考察したことを入力してください。'
          value={text5}
          onChange={(val: string) => {
            setText5(val);
          }}
        />
        <Text3>その他</Text3>
        <Textarea
          maxLength={400}
          placeholder='火元について考察したことを入力してください。'
          value={text6}
          onChange={(val: string) => {
            setText6(val);
          }}
        />
      </Content>
      <Step src={Step3} />
      <Content>
        <Text1>
          最も可能性が高いと思われる火事の原因を自由に記入してください。
        </Text1>
        <Textarea
          maxLength={400}
          placeholder='最も可能性が高いと思われる火事の原因を記入してください。'
          value={text7}
          onChange={(val: string) => {
            setText7(val);
          }}
        />
        <ButtonWrapper>
          <MainButton
            disabled={text7 == ''}
            color='positive'
            size='large'
            onClick={() => goTo(`/${trainingId}/program1/result`)}
          >
            送信
          </MainButton>
        </ButtonWrapper>
      </Content>
    </Stage2AnswerWrapper>
  );
};

const Step = styled.img`
  display: block;
  margin-bottom: 1rem;
  height: 42px;
  width: auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

const Content = styled.div`
  padding: 0 40px;
  margin-bottom: 20px;
`;

const Step1List = styled.ul`
  list-style: none;
  margin-bottom: 30px;

  li {
    margin-bottom: 6px;
    display: flex;
    width: 100%;
    align-items: center;
  }
`;

const ListTitle = styled.div`
  height: 48px;
  min-width: 80px;
  flex: 1;
  color: white;
  background: ${InstructionColor.secondary};
  border: 1px solid ${InstructionColor.secondary};
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

const ListContent = styled.div`
  background: white;
  height: 48px;
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #ced4da;
  border-left: none;

  padding: 0 10px;

  font-size: 1.8rem;
  white-space: nowrap;

  > div {
    margin: 0 12px;
  }
`;

const Stage2AnswerWrapper = styled.div`
  padding: 30px 0;
  overflow-y: scroll;
  flex: 6;
`;

const Text1 = styled.p`
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Text2 = styled.p`
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
`;

const Text3 = styled.p`
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default Stage2Answer;
