import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import ListBg from '../../../../../static/png/listbg.png';
import Stage3Icon0 from '../../../../../static/png/stage3/2-3_image1.png';
import Stage3Icon1 from '../../../../../static/png/stage3/2-3_image2.png';
import Stage3Icon2 from '../../../../../static/png/stage3/2-3_image3.png';
import Stage3Icon3 from '../../../../../static/png/stage3/2-3_image4.png';
import Stage3Icon4 from '../../../../../static/png/stage3/2-3_image5.png';
import Stage3Icon5 from '../../../../../static/png/stage3/2-3_image6.png';
import Stage3Icon6 from '../../../../../static/png/stage3/2-3_image7.png';
import Stage3Icon7 from '../../../../../static/png/stage3/2-3_image8.png';
import Stage3Icon8 from '../../../../../static/png/stage3/2-3_image9.png';
import DetailWindow from '../DetailWindow';
import { SingleUser } from '../Program3Step3';
import { GetQuestion } from '../Question';
import { answer1, answer2, question } from '../Question.json';
import { Program3State } from '../Types';

interface Program3QuestionProps {}

const getIcon = (i: number) => {
  const iconList = [
    Stage3Icon0,
    Stage3Icon1,
    Stage3Icon2,
    Stage3Icon3,
    Stage3Icon4,
    Stage3Icon5,
    Stage3Icon6,
    Stage3Icon7,
    Stage3Icon8,
  ];
  return iconList[i];
};

interface SingleContentProps {
  student: any;
  id: number;
}

const SingleContent: React.FC<SingleContentProps> = props => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <ListChild
      key={props.id}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <Content>
        <Person>
          <Icon>
            <img src={getIcon(props.id)} />
          </Icon>
          <div>
            <h3>
              {props.student.title}
              <i>{props.student.call}</i>
            </h3>
          </div>
        </Person>
      </Content>
      <Detail isOpen={isOpen}>
        <p>{props.student.detail}</p>
      </Detail>
      <span></span>
    </ListChild>
  );
};

const Program3Question: React.FC<Program3QuestionProps> = () => {
  const location = useLocation();
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet] = useState<Program3State>(
    `program3-${trainingId}-resultSet`,
    location.state as Program3State
  );
  const [currentFocus, setCurrentFocus] = useState<number>(
    `program3-${trainingId}-currentFocus`,
    -1
  );
  const getStudent = (id: number) => {
    let ret = null;
    question.map(single => {
      if (single.id == id) {
        ret = single;
      }
    });
    return ret;
  };

  const answer = GetQuestion(resultSet?.stage1?.step3);

  return (
    <List>
      <h2>誰かの回答</h2>
      <Desc>クリックすると生徒の詳細な説明が表示されます。</Desc>
      <ul>
        {answer.map((single: any, i: number) => {
          return (
            <ListContent key={i}>
              <div>
                <p>{single.name}</p>
              </div>
              <ul>
                {single.answers.map((inner: any, n: number) => {
                  return (
                    <SingleContent
                      student={getStudent(inner)}
                      id={inner}
                      key={n}
                    />
                  );
                })}
              </ul>
            </ListContent>
          );
        })}
      </ul>
      {currentFocus != -1 && <DetailWindow id={currentFocus} />}
    </List>
  );
};
const ListContent = styled.div`
  list-style: none;
  margin: 0;
  box-sizing: border-box;
  margin-bottom: 4px;
  border: 3px solid #00c355;

  > div:first-child > p {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 6px;
    background: #00c355;
    color: white;
    margin: 0 -2px;
    margin-top: -2px;
    padding-bottom: 2px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > ul {
    margin: 4px;
  }
`;

const Detail = styled.div`
  overflow: hidden;
  transition: 0.2s;

  p {
    margin: 10px !important;
    font-size: 1.4rem !important;
    text-align: left !important;
  }

  ${(p: SingleProps) => {
    return p.isOpen
      ? `
  max-height: 400px;
  `
      : `
  max-height: 0;
  `;
  }}
`;

interface SingleProps {
  isOpen: boolean;
}

const Desc = styled.div`
  background: white;
  border: 1px solid #ebebeb;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  margin: 0 24px;
  margin-bottom: 10px;
  padding: 4px 0;
`;

const List = styled.div`
  width: 450px;

  border-image: url(${ListBg});
  border-image-slice: calc(9 / 100 * 100%) calc(9 / 100 * 100%)
    calc(9 / 100 * 100%) fill;
  border-image-width: 50px 38px;

  padding-top: 60px;
  padding-bottom: 30px;
  margin-bottom: 20px;

  h2 {
    text-align: center;
    font-size: 2.8rem;
  }

  > ul {
    margin: 0 24px;
  }
`;

const ListChild = styled.li`
  list-style: none;
  margin: 0;
  box-sizing: border-box;
  margin-bottom: 4px;

  > div:first-child > p {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 6px;
    background: #00c355;
    color: white;
    margin: 0 -2px;
    margin-top: -2px;
    padding-bottom: 2px;
  }

  &:last-child {
    margin-bottom: 0;

    span {
      display: none;
    }
  }

  > ul {
    margin: 4px;
  }

  span {
    width: 100%;
    border-bottom: 2px solid #ebebeb;
    height: 2px;
    display: block;
    margin-top: 2px;
  }

  &:last-child {
    span {
      border: none;
    }
  }
`;

const Content = styled.div`
  cursor: pointer;
  padding: 0;
  user-select: none;

  transition: 0.2s;
  border-radius: 10px;
`;

const Person = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-size: 2rem;
  }
  p {
    font-size: 1.6rem;
  }

  i {
    font-size: 1.6rem;
    font-style: initial;
    margin-left: 3px;
  }

  transition: 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const Icon = styled.div`
  overflow: hidden;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  position: relative;

  img {
    position: absolute;
    width: 62px;
    height: 62px;
    left: -7px;
    top: -2px;
  }
`;

export default Program3Question;
